import React from 'react';
import Urubuto from '../../assets/images/Urubuto_Logo.svg';
import Menu from './Menu';

type Props = {
  children: React.ReactNode,
  menuItems: {
    id: number,
    icon: React.ReactNode,
    menuName: string,
    path: string
  }[]
};

const Layout: React.FC<Props> = ({ children, menuItems }: Props) => (
  <div className="flex h-screen bg-gray-50">
    {/* <!-- Desktop sidebar --> */}
    <aside className="z-20 hidden w-64 overflow-y-auto
      overflow-x-hidden bg-blue md:block flex-shrink-0"
    >
      <div className="text-gray-500 dark:text-gray-400">
        <div className="w-full text-lg font-bold text-white pt-5 pl-6">
          <img src={Urubuto} alt="" width="173.07" height="50" />
          {/* <span></span> */}
        </div>
        <ul className="mt-10">
          {menuItems.map(({
            id, menuName, path, icon,
          }) => <Menu key={id} name={menuName} path={path} icon={icon} />)}
        </ul>
      </div>
    </aside>
    {/* <!-- Mobile sidebar -->
      <!-- Backdrop --> */}
    <div
      className="fixed inset-0 z-10 flex items-end
        bg-black bg-opacity-0 sm:items-center sm:justify-center"
    />
    <aside className="fixed inset-y-0 z-20 flex-shrink-0 w-64
      mt-16 overflow-y-auto bg-blue text-white  md:hidden"
    >
      <div className="py-4 text-gray-500 dark:text-gray-400">
        <div className="w-full text-lg font-bold pt-5 pl-6">
          Logo
        </div>
        <ul className="mt-10">
          {menuItems.map(({
            id, menuName, path, icon,
          }) => <Menu key={id} name={menuName} path={path} icon={icon} />)}
        </ul>
      </div>
    </aside>
    <div className="flex flex-col flex-1">
      {children}
    </div>
  </div>
);

export default Layout;
