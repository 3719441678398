/* eslint-disable max-len */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { API } from '../../../helpers';

export interface IToggleActivation {
   user_id: string,
   action: 'activate' | 'deactivate',
}

export interface ICreateUser {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  gender: string;
  phone_number: string;
  role: string;
  school_id: string
}

interface IUpdateSchool {
  user_id: string;
  body: Record<string, any>
}

const api = new API();

const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

class System {
  user: Record<string, any>

  constructor() {
    this.user = currentUser;
  }

  fetchPaymentAccounts = (
    page: number,
    pageSize: number,
    searchString: string,
  ): Promise<any> => api.get(
    // eslint-disable-next-line max-len
    `/schools/accounts?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`,
    (res) => res,
  );

  changePayStatus = (
    account: string,
    action: 'approve' | 'reject' | string,
    school: string,
  ): Promise<any> => api.put(
    `/schools/${school}/accounts/${account}/${action}`,
    {},
    (res) => toast.success(res.message),
  );

  getUniversities = (
    page = 0,
    pageSize = 20,
    searchString = '',
  // eslint-disable-next-line max-len
  ): Promise<any> => api.get(`/schools?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`, (res) => res);

  getUsers = (
    page: number,
    pageSize: number,
    searchString: string,
  // eslint-disable-next-line max-len
  ): Promise<any> => api.get(`/users?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`, (res) => res);

  getTransactions = (
    page: number,
    pageSize: number,
    searchString: string,
    start: string,
    end: string,
  // eslint-disable-next-line max-len
  ): Promise<any> => api.get(`/transactions?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}${start.length > 0 && end.length > 0 ? `&start_date=${start}&end_date=${end}` : ''}`, (res) => res);

  downloadTransactions = (): Promise<any> => api.get('/transactions/download', (res) => res)

  toggleUserActivation(payload: IToggleActivation): Promise<any> {
    return api.put(
      `/schools/${this.user?.id}/members/${payload?.user_id}/${payload?.action}`,
      {},
      (res) => toast.success(res.message),
    );
  }

  getStats = (): Promise<any> => api.get(
    '/schools/overview',
    (res) => res,
  );

  updateSchoolInfo = (payload: IUpdateSchool): Promise<any> => api.put(`/schools/${payload.user_id}`,
    payload.body,
    (res) => toast.success(res.message));

  createUser=(body: ICreateUser): Promise<any> => api.post('/auth/invitation',
    body,
    (res) => toast.success(res.message));
}

export { System };
