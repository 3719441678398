import React from 'react';
import Moment from 'moment';
import { useQuery } from 'react-query';
import { BiDownload } from 'react-icons/bi';
import { toast } from 'react-toastify';
import {
  Layout, Header, Content, Table, DateRange, Badge, Button,
} from '../../components';
import { menu } from './local-helpers/MenuItems';
import { System } from './local-helpers/requests';
import { tableReducer } from '../../components/Table/TableReducer';
import CustomContext from '../../contexts/CustomContext';
import TransactionDetails from './TransactionDetails';
import { convertJsonToXl, TransactionsHeaders } from '../../helpers/functions-and-objects';

const system = new System();

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const Transactions: React.FC = () => {
  const [searchString, setSearchString] = React.useState('');
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [isViewMore, setIsViewMore] = React.useState(false);
  const [transactionInfo, setTransactionInfo] = React.useState<Record<string, any>>({});

  React.useEffect(() => () => toast.dismiss(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Slip number',
        accessor: 'slip_number',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Payed by',
        accessor: (row: Record<string, any>) => `${row?.user?.first_name} ${row?.user?.last_name}`,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => `${original?.user?.first_name} 
        ${original?.user?.last_name}`,
      },
      {
        Header: 'Phone number',
        accessor: (row: Record<string, any>) => row?.user?.phone_number,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => `${original?.user?.phone_number}`,
      },
      {
        Header: 'School',
        accessor: (row: Record<string, any>) => row?.school?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.school?.name,
      },
      {
        Header: 'Department',
        accessor: (row: Record<string, any>) => row?.department?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.department?.name,
      },
      {
        Header: 'Payed on',
        accessor: 'payment_date_time',
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.payment_date_time)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      {
        Header: 'Term',
        accessor: 'term',
      },
      {
        Header: 'Academic year',
        accessor: 'academic_year',
      },
      // {
      //   Header: 'Type',
      //   accessor: 'type',
      // },
      {
        Header: 'Channel',
        accessor: 'channel',
      },
      {
        Header: "Recipient's bank account",
        accessor: (row: Record<string, any>) => row?.bank_account?.bank_account_number,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.bank_account?.bank_account_number
          || '',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          <Badge
            variant={original?.status === 'success' ? 'green' : 'red'}
          >
            {original?.status}
          </Badge>
        ),
      },
      {
        Header: '',
        accessor: 'view more',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          <button
            type="button"
            onClick={() => { setIsViewMore(true); setTransactionInfo(original); }}
            className="text-blue-alertText text-xs hover:underline "
          >
            View more
          </button>
        ),
      },
    ],
    [],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const { data, isSuccess, isLoading } = useQuery(['transactions',
    state.queryPageIndex,
    state.queryPageSize,
    searchString,
    start,
    end], () => system.getTransactions(
    state.queryPageIndex,
    state.queryPageSize,
    searchString,
    start,
    end,
  ));

  const {
    data: dtData,
    isSuccess: dtIsSuccess,
  } = useQuery('download transactions', () => system.downloadTransactions());

  const exportTransactions = (): void => {
    if (dtIsSuccess) {
      const dataToDownload = dtData?.data?.map((t: Record<string, any>) => [
        t?.academic_year,
        t?.sage_id || '',
        t?.amount || '',
        t?.account_number || '',
        t?.channel || '',
        t?.currency || '',
        t?.department?.name || '',
        t?.description || '',
        t?.external_transaction_id || '',
        t?.initial_slip_number || '',
        t?.created_at || '',
        t?.payer_names || '',
        t?.user?.phone_number || '',
        t?.school_name || '',
        t?.service_name || '',
        t?.slip_number || '',
        t?.term || '',
        t?.created_at || '',
        t?.updated_at || '',
        t?.type || '',
      ]);
      convertJsonToXl(dataToDownload, TransactionsHeaders);
    }
  };

  return (
    <Layout menuItems={menu}>
      <TransactionDetails
        isGasabo
        isViewMore={isViewMore}
        details={transactionInfo}
        onClose={() => { setIsViewMore(false); setTransactionInfo({}); }}
      />
      <Header />
      <Content title="Transactions">
        <CustomContext.Provider value={stateProvider}>
          <div className="w-full flex justify-between">
            <Button
              type="button"
              onClick={() => exportTransactions()}
              className="flex"
            >
              <BiDownload size={20} className="mr-1" />
              Transactions
            </Button>
            <DateRange timeFrame={(from, to) => { setStart(from); setEnd(to); }} />
          </div>
          <Table
            searchLabel="search transactions"
            columns={columns}
            data={isSuccess ? data?.data : []}
            meta={isSuccess ? data?.meta : []}
            countPage={
            isSuccess
              ? Math.ceil(state.totalCount / state.queryPageSize)
              : undefined
          }
            loading={isLoading}
            onChangeCallback={(e : any) => handleSearch(e)}
            search
          />
        </CustomContext.Provider>
      </Content>
    </Layout>
  );
};

export { Transactions };
