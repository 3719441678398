import React from 'react';
import { useQuery } from 'react-query';
import { IoIosPeople } from 'react-icons/io';
import { SiCashapp } from 'react-icons/si';
import { AiOutlineNumber } from 'react-icons/ai';
import { FaSchool, FaCalendarAlt, FaChevronDown } from 'react-icons/fa';
import { GiOrganigram } from 'react-icons/gi';
import Keys from 'lodash/keys';
import Entries from 'lodash/entries';
import { toast } from 'react-toastify';
import {
  Layout, Content, Header, StatCards,
} from '../../components';
import { menu } from './local-helpers/MenuItems';
import { menu as accMenu } from '../Accountant/MenuItems';
import School from './local-helpers/requests';
import { Role } from '../../helpers';
import {
  convertDayNumberToName,
  convertMonthNumberToName,
  ITransactionData,
  labelWeek,
  convertHourNumberToTime,
} from '../../helpers/functions-and-objects';
import AreaChartCustom from '../../components/Charts/AreaChart';

interface IStats {
  id: number;
  description: string;
  numbers: string;
  icon: React.ReactNode;
  iconBgColor: string;
}

const SchoolAdmin: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const schoolName = currentUser?.schools[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');
  const currentYear = new Date().getFullYear();
  const [filter, setFilter] = React.useState<string | Date | number>(currentYear);

  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const pastYears = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const API = new School();
  const [data, setData] = React.useState<Record<string, any>>({});
  const { isLoading } = useQuery(['stats', filter],
    () => API.fetchStats(currentUser.schools[0].id, filter), {
      onSuccess(res) {
        setData(res.data);
      },
    });
  let statistics: IStats[] = [];
  let ageChartData: Record<string, any>[] = [];
  let transactionsChartData: ITransactionData[] = [];
  let chartKey = '';
  let genderChartData: Record<string, any>[] = [];
  let campusChartData: Record<string, any>[] = [];

  React.useEffect(() => () => toast.dismiss(), []);

  const handleDateChange = (event: any): void => {
    setSelectedDate(event.target.value);
    setFilter(event.target.value);
    setIsDatePickerOpen(false);
  };

  if (Object.entries(data).length > 0) {
    Keys(data).map((key, i) => {
      switch (key) {
        case 'campuses_count':
          statistics = [
            ...statistics,
            {
              id: i,
              description: isGasabo ? '' : 'Number of Campuses',
              numbers: data?.campuses_count.toLocaleString(),
              icon: <FaSchool color="#898ea0" className="w-5 h-5" />,
              iconBgColor: 'bg-gray-light',
            },
          ];
          break;
        case 'students_count':
          statistics = [
            ...statistics,
            {
              id: i,
              description: isGasabo ? 'Registered Sellers' : 'Registered Students',
              numbers: data?.students_count.toLocaleString(),
              icon: <IoIosPeople color="#4186e0" className="w-5 h-5" />,
              iconBgColor: 'bg-blue-alert',
            },
          ];
          break;
        case 'departments_count':
          statistics = [
            ...statistics,
            {
              id: i,
              description: isGasabo ? 'Number of Zones' : 'Number of Departments',
              numbers: data?.departments_count.toLocaleString(),
              icon: <GiOrganigram color="#ff3062" className="w-5 h-5" />,
              iconBgColor: 'bg-red-alert',
            },
          ];
          break;
        case 'transactions_count':
          statistics = [
            ...statistics,
            {
              id: i,
              description: 'Number of Transactions',
              numbers: data?.transactions_count.toLocaleString(),
              icon: <AiOutlineNumber color="#ffb41d" className="w-5 h-5" />,
              iconBgColor: 'bg-yellow-light',
            },
          ];
          break;
        case 'transactions_count_today':
          statistics = [
            ...statistics,
            {
              id: i,
              description: isGasabo ? 'Number of Transactions Today' : '',
              numbers: data?.transactions_count_today.toLocaleString(),
              icon: <AiOutlineNumber color="#7D40E7" className="w-5 h-5" />,
              iconBgColor: 'bg-purple-light',
            },
          ];
          break;
        case 'transactions_value_today':
          statistics = [
            ...statistics,
            {
              id: i,
              description: isGasabo ? 'Transactions Value Today' : '',
              numbers: `${data?.transactions_value_today.toLocaleString()} RWF`,
              icon: <SiCashapp color="#FF993D" className="w-5 h-5" />,
              iconBgColor: 'bg-orange-light',
            },
          ];
          break;
        case 'transactions_total':
          statistics = [
            ...statistics,
            {
              id: i,
              description: 'Transactions Value',
              numbers: `${data?.transactions_total.toLocaleString()} RWF`,
              icon: <SiCashapp color="#00EA81" className="w-5 h-5" />,
              iconBgColor: 'bg-green-alert',
            },
          ];
          break;
        case 'users_age_range':
          // eslint-disable-next-line array-callback-return
          Entries(data?.users_age_range).map((a) => {
            ageChartData = [
              ...ageChartData,
              {
                range: a[0].split('_').splice(1).join(' '),
                students: a[1],
              },
            ];
            return ageChartData;
          });
          break;
        case 'transactions_per_month':
          transactionsChartData = [...data?.transactions_per_month].map((transaction) => ({
            ...transaction,
            month: convertMonthNumberToName(parseInt(transaction.month, 10)),
            total_amount: Math.round(parseFloat(transaction.total_amount)),
            transaction_count: Math.round(parseFloat(transaction.transaction_count)),
          }));
          chartKey = 'month';
          break;
        case 'transactions_per_day':
          transactionsChartData = [...data?.transactions_per_day].map((transaction) => ({
            ...transaction,
            day_of_week: convertDayNumberToName(parseInt(transaction.day_of_week, 10)),
            total_amount: Math.round(parseFloat(transaction.total_amount)),
            transaction_count: Math.round(parseFloat(transaction.transaction_count)),
          }));
          chartKey = 'day_of_week';
          break;
        case 'transactions_per_week':
          transactionsChartData = [...data?.transactions_per_week].map((transaction) => ({
            ...transaction,
            week_of_month: labelWeek(parseInt(transaction.week_of_month, 10)),
            total_amount: Math.round(parseFloat(transaction.total_amount)),
            transaction_count: Math.round(parseFloat(transaction.transaction_count)),
          }));
          chartKey = 'week_of_month';
          break;
        case 'transactions_per_hour':
          transactionsChartData = [...data?.transactions_per_hour].map((transaction) => ({
            ...transaction,
            hour_of_day: convertHourNumberToTime(parseInt(transaction.hour_of_day, 10)),
            total_amount: Math.round(parseFloat(transaction.total_amount)),
            transaction_count: Math.round(parseFloat(transaction.transaction_count)),
          }));
          chartKey = 'hour_of_day';
          break;
        case 'transactions':
          transactionsChartData = [...data?.transactions].map((transaction) => ({
            ...transaction,
            hour_of_day: convertHourNumberToTime(parseInt(transaction.hour_of_day, 10)),
            total_amount: Math.round(parseFloat(transaction.total_amount)),
            transaction_count: Math.round(parseFloat(transaction.transaction_count)),
          }));
          chartKey = 'hour_of_day';
          break;
        case 'users_per_gender':
          [...data?.users_per_gender].splice(1).map((g) => {
            genderChartData = [
              ...genderChartData,
              {
                name: g.gender,
                number: parseInt(g.count, 10),
              },
            ];
            return genderChartData;
          });
          break;
        case 'transactions_per_campus':
          if (data?.transactions_per_campus !== null) {
            [...data?.transactions_per_campus].map((c) => {
              campusChartData = [
                ...campusChartData,
                {
                  name: c.campus_name,
                  number: parseInt(c.sum, 10),
                },
              ];
              return campusChartData;
            });
          } else return [];
          break;
        default:
          return null;
      }
      return null;
    });
  }

  return (
    <Layout
      menuItems={
        currentUser?.role === Role.SCHOOL_ADMIN
          ? menu
          : currentUser?.role === Role.ACCOUNTANT
            ? accMenu
            : []
      }
    >
      <Header />
      <Content title={`${currentUser?.schools[0]?.name}'s workspace`}>
        <StatCards stats={statistics} />
        <div
          className="w-full  p-4 bg-white
      rounded-lg border-gray-bg border shadow-xs dark:bg-gray-800"
        >
          <div className="inline-flex rounded-md shadow-sm mb-4 relative">
            {/* Day Button */}
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium 
          ${filter === 'day' ? 'text-blue-700 bg-white' : 'text-gray-900 bg-white'}
          border border-gray-200 rounded-l hover:bg-gray-100`}
              onClick={() => setFilter('day=true')}
            >
              Day
            </button>

            {/* Week Button */}
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium
          ${filter === 'week=true' ? 'text-blue-700 bg-white' : 'text-gray-900 bg-white'}
          border-t border-b border-gray-200 hover:bg-gray-100`}
              onClick={() => setFilter('week=true')}
            >
              Week
            </button>

            {/* Month Button */}
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium
          ${filter === 'month' ? 'text-blue-700 bg-white' : 'text-gray-900 bg-white'}
          border-t border-b border-l border-gray-200 hover:bg-gray-100`}
              onClick={() => setFilter('month=true')}
            >
              Month
            </button>

            {/* Year Button with Dropdown */}
            <div className="relative">
              <button
                type="button"
                className={`px-4 py-2 text-sm font-medium 
            ${filter === 'year' ? 'text-blue-700 bg-white' : 'text-gray-900 bg-white'}
            border border-gray-200 hover:bg-gray-100 flex items-center`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Year
                <FaChevronDown className="w-3 h-3 ml-2" />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-28 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-10">
                  {pastYears.map((year) => (
                    <button
                      type="button"
                      key={year}
                      onClick={() => {
                        setFilter(year);
                        setIsDropdownOpen(false);
                      }}
                      className="w-full px-2 py-2 text-sm text-left hover:bg-gray-100"
                    >
                      {year}
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Specific Button */}
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium 
          ${filter === 'specific' ? 'text-blue-700 bg-white' : 'text-gray-900 bg-white'}
          border border-gray-200 rounded-r hover:bg-gray-100 flex items-center`}
              onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
            >
              <FaCalendarAlt className="w-3 h-3 mr-2" />
              Specific
            </button>

            {/* Date Picker */}
            {isDatePickerOpen && (
              <input
                type="date"
                className="absolute right-0 w-auto p-2 mt-12 p-2 border border-gray-200 rounded-md bg-white shadow-lg z-10"
                value={selectedDate || ''}
                onChange={handleDateChange}
              />
            )}
          </div>
          <div className="w-full h-80">
            <AreaChartCustom data={transactionsChartData} xKey={chartKey} />
          </div>
        </div>
      </Content>
    </Layout>
  );
};
export default SchoolAdmin;
