import React from 'react';
import { useFormik } from 'formik';
import { useQuery, useMutation } from 'react-query';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  InputText,
  TextArea,
  Button,
  FormLayout,
  Select,
} from '../../../components';
import School, { ISetFees } from '../local-helpers/requests';
import { ISelectedValue } from '../../../components/Select';
import { getActiveAccs } from '../../../helpers/functions-and-objects';

const CreateService: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

  let accounts: ISelectedValue[] = [];
  const API = new School();
  React.useEffect(() => () => toast.dismiss(), []);

  const acc = useQuery('payment accounts', () => API.fetchAccounts(currentUser.schools[0].id));

  const fees = useMutation((payload: ISetFees) => API.setFees(payload, currentUser.schools[0].id));

  const formik = useFormik({
    initialValues: {
      fee_name: '',
      fee_description: '',
      fee_amount: '',
      fee_account: '',
    },
    validationSchema: Yup.object({
      fee_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required('Fee name is required.'),
      fee_description: Yup.string().min(10, 'Must be at least 10 characters'),
      fee_amount: Yup.number()
        .min(3, 'Must be at least 3 numbers'),
      fee_account: Yup.number().required('Payment account is required.'),
    }),
    onSubmit: (values) => {
      fees.mutate({
        name: values.fee_name,
        description: values.fee_description,
        amount: parseInt(values.fee_amount, 10),
        account_id: parseInt(values.fee_account, 10),
      });
    },
  });

  if (acc.isSuccess) {
    const acct = getActiveAccs(acc?.data?.data);
    acct?.map((a: Record<string, number>) => {
      accounts = [
        ...accounts,
        { label: `${a.type}/${a.bank_account_number}`, value: a.id },
      ];
      return accounts;
    });
  }

  if (fees.isSuccess) {
    formik.resetForm();
  }

  return (
    <span>
      <FormLayout
        title="Setting service type"
        description="Where to set the kind of service to pay for and the corresponding amount."
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-5">
            <InputText
              id="fee_name"
              label="name"
              placeholder="Service name"
              value={formik.values.fee_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={
                formik.touched.fee_name && formik.errors.fee_name
                  ? formik.errors.fee_name
                  : ''
              }
            />

            <InputText
              id="fee_amount"
              label="cost"
              placeholder="Service cost "
              value={formik.values.fee_amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={
                formik.touched.fee_amount && formik.errors.fee_amount
                  ? formik.errors.fee_amount
                  : ''
              }
              optional
            />
            <TextArea
              id="fee_description"
              label="description"
              placeholder="Description of the service being payed..."
              rows={4}
              style={{ resize: 'none' }}
              value={formik.values.fee_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={
                formik.touched.fee_description && formik.errors.fee_description
                  ? formik.errors.fee_description
                  : ''
              }
              optional
            />
            <Select
              id="fee_account"
              placeholder={acc.isLoading ? 'fetching accounts...' : 'Select account...'}
              label="Payment account"
              onChange={(option: ISelectedValue) => {
                formik.setFieldValue('fee_account', option.value);
              }}
              onBlur={formik.handleBlur}
              options={accounts}
              errorMsg={
                formik.touched.fee_account && formik.errors.fee_account
                  ? formik.errors.fee_account
                  : ''
              }
            />
          </div>
          <div className="mt-2 flex w-full justify-end">
            <Button
              type="button"
              variant="secondary"
              onClick={() => formik.resetForm()}
            >
              Cancel
            </Button>
            <Button type="submit" className="ml-2" loading={fees.isLoading}>
              Set Fee
            </Button>
          </div>
        </form>
      </FormLayout>
    </span>
  );
};

export default CreateService;
