/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  InputText, Card, Button,
} from '../components';
import Main from '../assets/images/main.svg';
import { ROUTE } from '../routes';
import { Role, API } from '../helpers';
import Urubuto from '../assets/images/Urubuto_Logo.svg';

const api = new API();

interface ILogin {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [user, setUser] = React.useState<Record<string, any>>({});
  const {
    mutate, isLoading,
  } = useMutation(
    (payload: ILogin) => api.post('/auth/login', payload, (res) => res),
    {
      onSuccess(res) {
        setUser(res.data);
      },
    },
  );

  React.useEffect(() => () => toast.dismiss(), []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
      password: Yup.string()
        // .min(6, 'Must be at least 6 characters.')
        .required('Password is required.'),
    }),
    onSubmit: (values) => { mutate(values); },
  });

  if (user.role) {
    localStorage.setItem('currentUser', JSON.stringify(user));

    if (user.role === Role.ADMIN) return <Redirect to={ROUTE.ADMIN} />;
    if (user.role === Role.SCHOOL_ADMIN || user.role === Role.ACCOUNTANT) return <Redirect to={ROUTE.SCHOOL_ADMIN} />;
  }

  return (
    <div className="w-full flex flex-wrap">
      {/* Login section */}
      <div
        className="w-full md:w-1/2 flex flex-col
      justify-center items-center bg-blue"
      >
        <div className="absolute top-6 left-6">
          <img src={Urubuto} alt="" width="173.07" height="50" />
        </div>
        <div className="w-full flex justify-center mt-8">
          <Card width={370}>
            <div className="flex flex-col justify-center md:justify-start ">
              <p className="text-center text-3xl text-blue">Login.</p>
              <form
                className="flex flex-col pt-3 md:pt-8"
                onSubmit={formik.handleSubmit}
              >
                <InputText
                  type="email"
                  label="your e-mail/phone number"
                  id="email"
                  placeholder="doe@bkth.rw"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  errorMsg={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ''
                  }
                />
                <InputText
                  type="password"
                  label="password"
                  id="password"
                  placeholder="*****"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  errorMsg={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : ''
                  }
                />
                <p
                  className="text-right font-light text-xs
                mt-3 mr-5 text-blue hover:underline"
                >
                  Forgot password?
                </p>
                <Button type="submit" variant="primary" className="mt-6" loading={isLoading}>
                  Log in
                </Button>
              </form>
            </div>
          </Card>
        </div>
      </div>

      {/* image section */}
      <div className="w-1/2 shadow-2xl">
        <div
          className=" w-full h-screen bg-white
         flex flex-col justify-center items-center"
        >
          <img src={Main} width="500" height="264.26" alt="main cover" />
          <div className=" mt-8 w-6/12">
            <h1 className="text-green font-extrabold text-4xl">
              School management made easy
            </h1>
            <p className="text-blue text-lg font-light">
              URUBUTO is a comprehensive school management solution developed
              for schools, students and parents. The intent is to maximize the
              productivity of the school in providing the best education a
              student can have.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
