import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card } from '../components';
import NotFound from '../assets/images/not_found.svg';

const FourOFour: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => () => toast.dismiss(), []);

  return (
    <div className="flex flex-col h-screen overflow-hidden
  justify-center items-center"
    >
      <Card width={500}>
        <div>
          <h1 className="text-7xl text-gray font-bold
         w-full flex justify-center"
          >
            404
          </h1>
          <img src={NotFound} alt="" className="my-3" />
          <h1 className="text-3xl text-gray mb-2">Ooops!Page not found</h1>
          <p className=" text-gray-dark">
            Ooops! The page you are looking for does not exist.
            It might have been moved or deleted
          </p>
          <Button
            type="button"
            variant="primary"
            onClick={() => history.go(-1)}
            className="mt-5"
          >
            Go back
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default FourOFour;
