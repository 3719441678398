export const customStyles = {
  control: (base: any, state: any): any => ({
    ...base,
    border: 'solid 1px #e3e8f0',
    background: '#f0f2f7',
    borderRadius: '10px',
    padding: '4px 0px',
    // This line disable the blue border
    boxShadow: state.isFocused ? '0px 8px 10px #e3e8f0' : 0,
    '&:hover': {
      border: 'solid 1px #e3e8f0',
    },
    outline: 'none',
  }),
  indicatorSeparator: (base: any): any => ({
    ...base,
    display: 'none',
  }),
};
