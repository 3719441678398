/* eslint-disable react/react-in-jsx-scope */
import {
  BiHome,
} from 'react-icons/bi';
// import { HiOutlineUsers } from 'react-icons/hi';
import { IoSchoolOutline } from 'react-icons/io5';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { ROUTE } from '../../routes';

export const menu = [
  {
    id: 1,
    icon: <BiHome color="#ffffff" />,
    menuName: 'Home',
    path: ROUTE.SCHOOL_ADMIN,
  },
  {
    id: 2,
    icon: <IoSchoolOutline color="#ffffff" />,
    menuName: 'Students',
    path: ROUTE.SCHOOL_ADMIN_STUDENTS,
  }, {
    id: 2,
    icon: <IoSchoolOutline color="#ffffff" />,
    menuName: 'Transactions',
    path: ROUTE.SCHOOL_ADMIN_TRANSACTIONS,
  },
  {
    id: 3,
    icon: <CgArrowsExchangeAlt color="#ffffff" />,
    menuName: 'Transactions',
    path: ROUTE.SCHOOL_ADMIN_TRANSACTIONS,
  },
];
