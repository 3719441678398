/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Moment from 'moment';
import Tooltip from 'react-tooltip';
import { FaUserEdit } from 'react-icons/fa';
import { BiDownload, BiFilterAlt } from 'react-icons/bi';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { toast } from 'react-toastify';
import {
  Table, Button, Filter,
} from '../../../../components';
import { IFilters } from '../../../../components/Table/Filter';
import School, { IStatus } from '../../local-helpers/requests';
import {
  convertJsonToXl,
  getOnlyStudents,
  UniqueArray,
  StudentsHeaders,
} from '../../../../helpers/functions-and-objects';
import { tableReducer } from '../../../../components/Table/TableReducer';
import CustomContext from '../../../../contexts/CustomContext';
import UpdateStudent from './UpdateStudent';
import { Role } from '../../../../helpers';
import CreateStudent from './CreateStudent';
import { ISelectedValue } from '../../../../components/Select';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const genders = [{ id: '1', name: 'male' }, { id: '2', name: 'female' }];

const Members: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const schoolName = currentUser?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');
  const API = React.useMemo(() => new School(), []);
  const queryClient = useQueryClient();
  const campuses: ISelectedValue[] = [];
  const departments: ISelectedValue[] = [];
  const [isEdit, setIsEdit] = React.useState(false);
  const [isStudentModal, setStudentModal] = React.useState(false);
  const [studentInfo, setStudentInfo] = React.useState<Record<string, any>>({});
  const [department, setDepartment] = React.useState<Array<IFilters>>([]);
  const [searchString, setSearchString] = React.useState('');
  const [checkedDepartments, setCheckedDepartments] = React.useState<Array<string>>([]);
  const [selectedGender, setSelectedGender] = React.useState('');

  React.useEffect(() => {
    const depts: Record<string, any> = queryClient.getQueryState(['departments']) || {};

    depts
      ?.data
      ?.data
      ?.map((dept: Record<string, any>) => setDepartment(
        (prevState) => ([...prevState, { name: dept.name, id: dept.id }]),
      ));
    return () => toast.dismiss();
  }, [queryClient]);

  const columns = React.useMemo(
    () => [
      {
        Header: isGasabo ? 'Stand No.' : 'Registration No.',
        accessor: (row: Record<string, any>) => row?.user?.registration_number,
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.registration_number,
      }, {
        Header: isGasabo ? 'RWF To be Paid' : 'Sage ID',
        accessor: 'sage_id',
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => (isGasabo ? original?.sage_id.toLocaleString() : original?.sage_id),
      },
      {
        Header: 'First Name',
        accessor: (row: Record<string, any>) => row?.user?.first_name,
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.first_name,
      },
      {
        Header: 'Last Name',
        accessor: (row: Record<string, any>) => row?.user?.last_name,
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => original?.user?.last_name,
      },
      // {
      //   Header: 'Gender',
      //   accessor: (row: Record<string, any>) => row?.user?.gender,
      //   Cell: ({
      //     row:
      //     { original },
      //   }: Record<string, any>): React.ReactNode => original?.user?.gender,
      // },
      {
        Header: isGasabo ? 'Zone' : 'Department',
        accessor: (row: Record<string, any>) => row?.department?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.department?.name,
      },
      {
        Header: isGasabo ? 'Location' : 'Campus',
        accessor: (row: Record<string, any>) => row?.campus?.name,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.campus?.name,
      },
      {
        Header: 'Created at',
        accessor: (row: Record<string, any>) => row?.created_at,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.created_at)
          .format('DD/MM/YYYY, h:mm:ss a') || 'created at',
      },
      {
        Header: 'Updated at',
        accessor: (row: Record<string, any>) => row?.updated_at,
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.updated_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      currentUser?.role === Role.SCHOOL_ADMIN ? {
        Header: 'Edit',
        accessor: 'edit',
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => (
          <Button
            type="button"
            variant="tertiary"
            size="small"
            onClick={() => { setIsEdit(true); setStudentInfo(original); }}
          >
            <FaUserEdit size={18} />
          </Button>
        )
        ,
      } : { id: 'no-edit-allowed', Cell: () => '' },
      {
        Header: 'Status',
        accessor: (row: any) => row?.status,
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const {
            mutate, isLoading,
          } = useMutation((payload: IStatus) => API.changeStatus(payload, currentUser.schools[0].id));
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  return currentUser?.role === Role.SCHOOL_ADMIN
                    ? mutate({
                      action: original?.status === 'active'
                        ? 'deactivate'
                        : 'activate',
                      user_id: original?.user_id,
                    })
                    : () => null;
                }}
              >
                <button
                  type="submit"
                  data-tip
                  data-for="status-change"
                  className={`text-xs px-2 font-medium
                ${original?.status === 'active'
                    ? 'text-green-alertText bg-green-alertText'
                    : 'text-red-alertText bg-red-alertText'
                  }
                  bg-opacity-10 rounded py-0.5 mr-1`}
                >
                  {isLoading ? 'changing...' : original?.status}
                </button>
              </form>

              {currentUser?.role === Role.SCHOOL_ADMIN && (
              <Tooltip id="status-change" type="light">
                Click to change status.
              </Tooltip>
              )}
            </>
          );
        },
      },
    ],
    [API, currentUser?.role, currentUser.schools],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const {
    isLoading, data, isSuccess,
  } = useQuery(
    ['members',
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      checkedDepartments,
      selectedGender],
    () => API.fetchMembers(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      checkedDepartments,
      selectedGender,
      currentUser.schools[0].id,
    ),
  );

  const {
    data: dData, isSuccess: dSuccess,
  } = useQuery(
    ['members download'],
    () => API.downloadMembers(currentUser.schools[0].id),
  );

  const exportStudents = (): void => {
    if (dSuccess) {
      const docName = `Students list ${Moment().format('DD-MM-YYYY HH:MM')}`;
      const dataToDownload = dData?.data?.map((s: Record<string, any>) => [
        s.user.registration_number,
        s.sage_id || '',
        s?.user?.first_name,
        s?.user?.last_name,
        s?.user?.gender || '',
        s?.department?.name,
        s?.campus?.name]);
      convertJsonToXl(dataToDownload, StudentsHeaders, docName);
    }
  };

  return (
    <>
      <CreateStudent isGasabo isStudentCreated={isStudentModal} onClose={() => setStudentModal(false)} students={[]} campuses={campuses} departments={departments} />
      <UpdateStudent isUpdateStudent={isEdit} onClose={() => setIsEdit(false)} info={studentInfo} />
      <CustomContext.Provider value={stateProvider}>
        <div className="w-full flex justify-end">
          <Filter
            title={(
              <span className="flex">
                <BiFilterAlt size={20} className="mr-1" />
                {' '}
                <span>Filter by gender</span>
              </span>
)}
            filters={genders}
            onSelect={(args) => setSelectedGender(args.name)}
            radio
          />
          <Filter
            title={(
              <span className="flex">
                <BiFilterAlt size={20} className="mr-1" />
                {' '}
                <span>{isGasabo ? 'filter by zone' : 'filter by department'}</span>
              </span>
)}
            filters={new UniqueArray(department)}
            onCheck={(args) => setCheckedDepartments(args.map((a) => a.id))}
          />
          <Button type="button" className="flex" onClick={() => exportStudents()}>
            <BiDownload size={20} className="mr-1" />
            Download list
          </Button>
          &nbsp;&nbsp;
          <Button type="button" className="flex" onClick={(): void => { setStudentModal(true); }}>
            <AiOutlineUserAdd size={20} className="mr-1" />
            {isGasabo ? 'Register seller' : 'Register student'}
          </Button>
        </div>

        <Table
          searchLabel={isGasabo ? 'search sellers' : 'search students'}
          data={isSuccess ? getOnlyStudents(data?.data) : []}
          columns={columns}
          meta={isSuccess ? data?.meta : []}
          countPage={
            isSuccess
              ? Math.ceil(state.totalCount / state.queryPageSize)
              : undefined
          }
          loading={isLoading}
          onChangeCallback={(e: any) => handleSearch(e)}
          search
        />
      </CustomContext.Provider>
    </>
  );
};
export default Members;
