import React from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import {
  Button, InputText, Select, FormLayout,
} from '../../components';
import { API } from '../../helpers';
import { ISelectedValue } from '../../components/Select';

const AdminProfile: React.FC = () => {
  const request = new API();
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  React.useEffect(() => () => toast.dismiss(), []);

  const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  const {
    mutate, isLoading,
  } = useMutation((payload: any) => request.put(
    `/schools/${currentUser?.schools[0]?.id}/members/${currentUser?.UserId}`,
    payload,
    (res) => res,
  ));

  const formik = useFormik({
    initialValues: {
      sysap_first_name: currentUser?.first_name,
      sysap_username: currentUser?.username,
      sysap_gender: currentUser?.gender,
      sysap_last_name: currentUser?.last_name,
      sysap_email: currentUser?.email,
      sysap_phone: currentUser?.phone_number,
    },
    validationSchema: Yup.object({
      sysap_first_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required('First name is required.'),
      sysap_username: Yup.string().required('Username is required.'),
      sysap_gender: Yup.string().required('Select a gender.'),
      sysap_last_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required('Last name is required.'),
      sysap_email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
      sysap_phone: Yup.string().required('Phone number is required'),
    }),
    onSubmit: (values): any => {
      if (isEqual(formik.initialValues, values)) toast.warn('Information did not change');
      return mutate({ values });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout
        title="Your information"
        description="You can change information about your profile and update"
      >
        <div className="grid grid-cols-2 gap-5">
          <InputText
            id="sysap_first_name"
            type="text"
            placeholder=""
            label="first name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sysap_first_name}
          />
          <InputText
            id="sysap_last_name"
            type="text"
            placeholder=""
            label="last name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sysap_last_name}
          />
          <InputText
            id="sysap_email"
            type="text"
            placeholder=""
            label="email address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sysap_email}
          />
          <InputText
            id="sysap_username"
            type="text"
            placeholder=""
            label="username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sysap_username}
          />
          <Select
            id="sysap_gender"
            placeholder="Select gender..."
            label="gender"
            onChange={(option: ISelectedValue) => {
              formik.setFieldValue('gender', option.value);
            }}
            onBlur={formik.handleBlur}
            defaultValue={{
              value: formik.values.sysap_gender,
              label: formik.values.sysap_gender,
            }}
            options={genders}
          />

          <InputText
            id="sysap_phone"
            type="text"
            placeholder=""
            label="phone number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sysap_phone}
          />
        </div>
      </FormLayout>
      <div className="flex justify-end mt-6">
        <Button
          type="reset"
          variant="secondary"
          onClick={() => formik.resetForm()}
        >
          Reset
        </Button>
        <Button type="submit" className="ml-4" loading={isLoading}>
          Update profile
        </Button>
      </div>
    </form>
  );
};

export default AdminProfile;
