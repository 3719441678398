import React from 'react';

type Props = {
    children: React.ReactNode,
  // variant?: 'blue' | 'yellow' | 'red' | 'green',
  variant?: string,
    onClick?: React.EventHandler<React.FormEvent>
}

const Badge: React.FC<Props> = ({ children, variant, onClick }: Props) => {
  const getBadgeStyle = (): string => {
    switch (variant) {
      case 'green':
        return 'text-green bg-green';
      case 'yellow':
        return 'text-yellow bg-yellow';
      case 'red':
        return 'text-red bg-red';
      case 'blue':
        return 'text-blue bg-blue';
      default:
        return '';
    }
  };

  return (
    <button
      type="button"
      data-tip
      data-for="status-change"
      className={`text-xs px-2 font-medium
                ${getBadgeStyle()}
                  bg-opacity-10 rounded py-0.5 mr-1`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Badge;
