import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import {
  Modal, FormLayout, Button, InputText,
} from '../../components';
import School from './local-helpers/requests';

type Props = {
  isUpdateService: boolean;
  onClose: () => void;
  info: Record<string, any>;
};

const UpdateService: React.FC<Props> = ({ isUpdateService, onClose, info }) => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const API = new School();
  const [service, setService] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    setService(info);
    return () => toast.dismiss();
  }, [service, info]);

  const {
    mutate,
    isLoading,
  // eslint-disable-next-line max-len
  } = useMutation((payload: Record<string, any>) => API.updateService(payload, currentUser.schools[0].id));

  const formik = useFormik({
    initialValues: {
      s_sname: service?.name,
      s_sdescription: service?.description,
      s_samount: service?.amount,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      s_sname: Yup.string().min(3, 'Must be at least 3 characters.'),
      s_sdescription: Yup.string().min(3, 'Must be at least 3 characters.'),
      s_samount: Yup.number().min(3, 'Must be at least 3 characters.'),
    }),
    onSubmit: (values) => {
      if (!isEqual(formik.initialValues, values)) {
        mutate({
          body: {
            name: values.s_sname,
            description: values.s_sdescription,
            amount: values.s_samount,
          },
          id: service?.id,
        });
      } else toast.info('No changes were made');
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        isOpen={isUpdateService}
        title="Edit Service Information"
        onClose={onClose}
        footerContent={(
          <div className="flex justify-end">
            <Button type="reset" variant="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="ml-4"
              loading={isLoading}
              // onClick={formik.submitForm}
            >
              save changes
            </Button>
          </div>
      )}
      >
        <FormLayout
          title="Update information"
          description="Edit this service's information."
        >
          <InputText
            id="s_sname"
            type="text"
            label="service name"
            value={formik.values.s_sname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <InputText
            id="s_sdescription"
            type="text"
            label="description"
            value={formik.values.s_sdescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <InputText
            id="s_samount"
            type="text"
            label="amount"
            value={formik.values.s_samount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormLayout>
      </Modal>
    </form>
  );
};

export default UpdateService;
