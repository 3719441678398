/* eslint-disable react/react-in-jsx-scope */
import {
  BiPlus, BiCog,
} from 'react-icons/bi';
import { VscOrganization } from 'react-icons/vsc';
import { IoSchoolOutline } from 'react-icons/io5';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { RiDashboardLine } from 'react-icons/ri';
import { FaHandHolding } from 'react-icons/fa';
import { MdOutlineSell } from 'react-icons/md';
import { ROUTE } from '../../../routes';

const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
const schoolName = currentUser?.schools?.[0]?.name;
const isGasabo = schoolName?.includes('Gasabo');

const menu = [
  {
    id: 1,
    icon: <RiDashboardLine color="#ffffff" />,
    menuName: 'Dashboard',
    path: ROUTE.SCHOOL_ADMIN,
  },
  {
    id: 2,
    icon: <CgArrowsExchangeAlt color="#ffffff" />,
    menuName: 'Transactions',
    path: ROUTE.SCHOOL_ADMIN_TRANSACTIONS,
  },
  {
    id: 4,
    icon: <BiPlus color="#ffffff" />,
    menuName: 'Create Account',
    path: ROUTE.SCHOOL_ADMIN_CREATE_ACC,
  },
  {
    id: 5,
    icon: isGasabo ? <MdOutlineSell color="#ffffff" /> : <IoSchoolOutline color="#ffffff" />,
    menuName: isGasabo ? 'Sellers Management' : 'Student Management',
    path: ROUTE.SCHOOL_ADMIN_STUDENTS,
  },
  {
    id: 6,
    icon: <VscOrganization color="#ffffff" />,
    menuName: 'Users',
    path: ROUTE.SCHOOL_ADMIN_USERS,
  },
  {
    id: 7,
    icon: <FaHandHolding color="#ffffff" />,
    menuName: 'Services',
    path: ROUTE.SCHOOL_ADMIN_SERVICES,
  },
  {
    id: 8,
    icon: <BiCog color="#ffffff" />,
    menuName: 'Settings',
    path: ROUTE.SCHOOL_ADMIN_SETTINGS,
  },

  // {
  //   id: 8,
  //   icon: <FaMailBulk color="#ffffff" />,
  //   menuName: 'Communications',
  //   path: ROUTE.SCHOOL_ADMIN_COMMUNICATION,
  // },

];

if (isGasabo) {
  menu.push({
    id: 3,
    icon: <CgArrowsExchangeAlt color="#ffffff" />,
    menuName: 'Payment Report',
    path: ROUTE.SCHOOL_PAYMENTS_REPORT,
  });
}

export { menu };
