/* eslint-disable @typescript-eslint/no-this-alias */
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const Tabs: React.FC<Props> = ({ children }: Props) => {
  const [active, setActive] = React.useState(0);

  function select(i: number): any {
    setActive(i);
  }

  function renderTabs(): React.ReactNode {
    return React.Children.map(children, (item, i): React.ReactNode => {
      if (i % 2 === 0) {
        const activeClass = active === i
          ? 'text-green border-b-2 font-medium border-green' // bg-alert-green
          : 'text-gray';
        return (
          <button
            type="button"
            onClick={(e) => select(i)}
            className={`${activeClass}  
            py-4 px-6 hover:text-green focus:outline-none `}
          >
            {item}
          </button>
        );
      }
      return null;
    });
  }

  function renderContent(): React.ReactNode {
    return React.Children.map(children, (item, i): React.ReactNode => {
      if (i - 1 === active) {
        return (
          <div className="content p-4 border-t border-gray-light w-full text-black">
            {item}
          </div>
        );
      }
      return null;
    });
  }

  return (
    <div className="tabs">
      {renderTabs()}
      {renderContent()}
    </div>
  );
};

export default Tabs;
