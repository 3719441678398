import React from 'react';

type Props = {
  children: React.ReactNode;
  width: number;
};

const Card: React.FC<Props> = ({ width, children }: Props) => (
  <div
    className="p-10 bg-white rounded-md shadow"
    style={{
      width: `${width}px`,
    }}
  >
    {children}
  </div>
);

export default Card;
