import React from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  InputText,
  FormLayout,
  Button,
  Modal,
} from '../../../../components';
import School, {
  ICreateCampus,
} from '../../local-helpers/requests';

type Props = {
    isCreateCampus: boolean,
  onClose: () => void,
    isGasabo : boolean
}

const CreateCampus: React.FC<Props> = ({ isCreateCampus, onClose, isGasabo }: Props) => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const API = new School();
  const {
    mutate, isLoading,
  } = useMutation((payload: ICreateCampus) => API.createCampus(payload, currentUser.schools[0].id));

  React.useEffect(() => () => toast.dismiss(), []);

  const cFormik = useFormik({
    initialValues: { scha_campus_name: '', scha_campus_adrs: '' },
    validationSchema: Yup.object({
      scha_campus_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required(isGasabo ? 'Location name is required' : 'Campus name is required'),
      scha_campus_adrs: Yup.string()
        .min(6, 'Must be at least 6 charaters.')
        .required(isGasabo ? 'Location address is required' : 'Campus address is required'),
    }),
    onSubmit: (values) => {
      mutate({ name: values.scha_campus_name, address: values.scha_campus_adrs });
    },
  });

  return (
    <form onSubmit={cFormik.submitForm}>
      <Modal
        isOpen={isCreateCampus}
        title={isGasabo ? 'Create location' : 'Create campus'}
        onClose={onClose}
        footerContent={(
          <div className="flex justify-end">
            <Button
              type="submit"
              variant="primary"
              className="ml-4"
              loading={isLoading}
            >
              {isGasabo ? 'Create location' : 'Create campus'}
            </Button>
          </div>
        )}
      >
        <FormLayout
          title={isGasabo ? 'Location' : 'Campus'}
          description={isGasabo ? `Fill in the location name
          and the address. The address can be as detailed
           as possible."` : `Fill in the name of the campus
          and the address. The address can be as detailed
           as possible."`}
        >
          <InputText
            id="scha_campus_name"
            type="text"
            label="Name"
            placeholder={isGasabo ? 'Location name' : 'Campus name'}
            value={cFormik.values.scha_campus_name}
            onChange={cFormik.handleChange}
            onBlur={cFormik.handleBlur}
            errorMsg={
              cFormik.touched.scha_campus_name
              && cFormik.errors.scha_campus_name
                ? cFormik.errors.scha_campus_name
                : ''
            }
          />
          <InputText
            id="scha_campus_adrs"
            label="address"
            placeholder={isGasabo ? 'Location address' : 'Campus address'}
            value={cFormik.values.scha_campus_adrs}
            onChange={cFormik.handleChange}
            onBlur={cFormik.handleBlur}
            errorMsg={
              cFormik.touched.scha_campus_adrs
              && cFormik.errors.scha_campus_adrs
                ? cFormik.errors.scha_campus_name
                : ''
            }
          />
        </FormLayout>
      </Modal>
    </form>
  );
};

export default CreateCampus;
