import React from 'react';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import { useComponentVisible } from '../../hooks/useComponentVisible';
import { Button } from '..';
import './styles.css';

export interface IFilters {
  name: string,
  id: string
}

type Props = {
  filters: Array<IFilters>,
  title: React.ReactNode,
  onCheck?: (arg: IFilters[]) => void,
  onSelect?: (args: IFilters) => void,
  radio?: boolean
};

const Filter: React.FC<Props> = ({
  filters, onCheck, radio, onSelect, title,
}) => {
  const [checkedFilters, setCheckeddFilters] = React.useState<Array<IFilters>>([]);
  const [selectedOption, setSelectedOption] = React.useState<IFilters>({ id: '', name: '' });

  const uniqueFilters = React.useMemo(() => uniqBy(filters, (e) => e), [filters]);

  const handleChange = React.useCallback((filter: IFilters): void => {
    const selections: Array<IFilters> = checkedFilters;

    // find index
    const findIdx = selections.indexOf(filter);
    // Index > -1 means that the item exists and that the checkbox is checked
    // and in that case we want to remove it from the array and uncheck it

    if (findIdx > -1) checkedFilters.splice(findIdx, 1);
    else selections.push(filter);

    setCheckeddFilters(selections);
    if (onCheck !== undefined) onCheck(selections);
  }, [onCheck, checkedFilters]);

  const handleRadio = React.useCallback((filter: IFilters): void => {
    setSelectedOption(filter);
    if (onSelect !== undefined) onSelect(filter);
  }, [onSelect]);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  return (
    <div ref={ref} className="relative">
      <Button
        type="button"
        className="relative ml-2"
        variant="tertiary"
        onClick={() => setIsComponentVisible(true)}
      >
        {title}
      </Button>
      {isComponentVisible && (
        !radio ? (
          <div>
            <ul
              className="absolute right-0 w-56 max-h-64 h-auto p-2 mt-2
                    space-y-2 text-black bg-white border
                    border-gray-light rounded-md shadow-md z-50 overflow-scroll overflow-x-hidden"
            >
              {uniqueFilters?.map((filter) => (
                <li key={filter.id}>
                  <label className="check-container" htmlFor={filter.id}>
                    {filter.name}
                    <input
                      id={filter.id}
                      type="checkbox"
                      onChange={() => handleChange(filter)}
                      defaultChecked={checkedFilters.includes(filter)}
                    />
                    <span className="checkmark" />
                  </label>
                </li>
              ))}
            </ul>
            <div />
          </div>
        ) : (
          <ul className="absolute right-0 w-56 max-h-64 h-auto p-2 mt-2
                    space-y-2 text-black bg-white border
                    border-gray-light rounded-md shadow-md z-50 overflow-scroll overflow-x-hidden"
          >
            <li>
              <label className="container-radio" htmlFor="all-filters">
                All
                <input
                  type="radio"
                  name="filters"
                  id="all-filters"
                  onChange={() => handleRadio({ id: '', name: '' })}
                  defaultChecked={every(selectedOption, (option) => option === '')}
                />
                <span className="radio" />
              </label>
            </li>
            {uniqueFilters?.map((filter) => (
              <li>
                <label className="container-radio" htmlFor={filter.id}>
                  {filter.name}
                  <input
                    type="radio"
                    id={filter.id}
                    name="filters"
                    onChange={() => handleRadio(filter)}
                    defaultChecked={isEqual(selectedOption, filter)}
                  />
                  <span className="radio" />
                </label>
              </li>
            ))}
          </ul>
        )
      )}
    </div>
  );
};

export default Filter;
