import React from 'react';
import { useMutation } from 'react-query';
import {
  Button,
} from '../../components';
import { Profile } from './local-helpers/requests';

const request = new Profile();

const PasswordResetSchool: React.FC = () => {
  const { mutate, isLoading } = useMutation(() => request.resetPassword());

  return (
    <div className="w-6/12">
      <Button
        type="button"
        variant="primary"
        onClick={() => mutate()}
        loading={isLoading}
        disabled={isLoading}
      >
        Reset Password
      </Button>
    </div>
  );
};

export { PasswordResetSchool };
