/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import { API, Role, APIFD } from '../../../helpers';

const api = new API();
const apifd = new APIFD();

export interface ICreateDept {
  name: string;
  campus_id: number;
}

export interface ICreateCampus {
  name: string;
  address: string;
}
export interface IStudent {
  first_name: string;
  last_name: string;
  email?: string;
  registration_number: string;
  birth_date?: string;
  campus_id: number;
  department_id: number;
  phone_number: string;
  sage_id: string;
  gender: string;
}
export interface IUploadStudents {
  students: IStudent[];
}

export interface IPaymentAccount {
  name: string;
  type: string;
  bank_account_number: string;
}

export interface ISetFees {
  name: string;
  description?: string;
  amount: number;
  account_id: number;
}

export interface ICreateAccount {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  gender: string;
  phone_number: string;
  role: 'accountant';
  school_id: number
}

export interface IStatus {
user_id: string,
  action: string
}

class School {
  fetchFees(page: number, pageSize: number, searchString: string, schoolId: string): Promise<any> {
    return api.get(
      // eslint-disable-next-line max-len
      `/schools/${parseInt(schoolId, 10)}/fees?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`, (res) => res,
    );
  }

  fetchMembers(
    page: number,
    pageSize: number,
    searchString: string,
    checkedDepts: Array<string>,
    gender: string,
    schoolId: string,
  ): Promise<any> {
    return api.get(
      // eslint-disable-next-line max-len
      `/schools/${parseInt(schoolId, 10)}/members?${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}${checkedDepts.length > 0 ? `&departments=${checkedDepts.toString()}` : ''}${gender.length > 0 ? `&gender=${gender}` : ''}`,
      (res) => res,
    );
  }

  downloadMembers(schoolId: string): Promise<any> {
    return api.get(
      `/schools/${parseInt(schoolId, 10)}/members/download`,
      (res) => res,
    );
  }

  setFees(body: ISetFees, schoolId: string): Promise<any> {
    return api.post(
      `/schools/${parseInt(schoolId, 10)}/fees`,
      body,
      (res) => toast.success(res.message),
    );
  }

  fetchCampuses(schoolId: string): Promise<any> {
    return api.get(
      `/schools/${parseInt(schoolId, 10)}/campuses`,
      (res) => res,
    );
  }

  fetchDepartments(pageSize: number, schoolId: string): Promise<any> {
    return api.get(
      `/schools/${parseInt(schoolId, 10)}/departments?page=${1}&limit=${pageSize}`,
      (res) => res,
    );
  }

  fetchTransactions(
    page: number,
    pageSize: number,
    searchString: string,
    start: string,
    end: string,
    schoolId: string,
    departmentId?: number,
  ): Promise<any> {
    return api.get(
      `/transactions?school_id=${parseInt(schoolId, 10)}&${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}${start.length > 0 && end.length > 0 ? `&start_date=${start}&end_date=${end}` : ''}${departmentId ? `&department_id=${departmentId}` : ''}`,
      (res) => res,
    );
  }

  downloadTransactions = (): Promise<any> => api.get('/transactions/download', (res) => res);

  createDept(body: ICreateDept, schoolId: string): Promise<any> {
    return api.post(
      `/schools/${parseInt(schoolId, 10)}/departments`,
      body,
      (res) => toast.success(res.message),
    );
  }

  createCampus(body: ICreateCampus, schoolId: string): Promise<any> {
    return api.post(
      `/schools/${parseInt(schoolId, 10)}/campuses`,
      body,
      () => toast.success('The campus was created.'),
    );
  }

  uploadStudents(body: FormData, schoolId: string): Promise<any> {
    return apifd.post(
      `/schools/${parseInt(schoolId, 10)}/students/bulk`,
      body,
      (res) => toast.success(res.message),
    );
  }

  createStudent(body: IStudent, schoolId: string): Promise<any> {
    return api.post(
      `/schools/${parseInt(schoolId, 10)}/student/add`,
      body,
      (res) => toast.success(res.message),
    );
  }

  createPaymentAccount(body: IPaymentAccount, schoolId: string): Promise<any> {
    return api.post(
      `/schools/${parseInt(schoolId, 10)}/accounts`,
      body,
      (res) => toast.success(res.message),
    );
  }

  fetchAccounts(schoolId: string): Promise<any> {
    return api.get(
      `/schools/${parseInt(schoolId, 10)}/accounts`,
      (res) => res,
    );
  }

  createAccountantAcc = (body: ICreateAccount): Promise<any> => api.post(
    '/auth/invitation',
    body,
    (res) => toast.success(res.message),
  );

 fetchStats = (
   schoolId: string,
   filter: string | number | Date,
 ): Promise<any> => {
   // Initialize an array to hold the query parameters

   const queryParams = [];

   // Add school_id to the query params if it's provided
   if (schoolId) {
     queryParams.push(`school_id=${encodeURIComponent(schoolId)}`);
   }

   // Add the other params only if they are provided
   if (typeof filter === 'number') {
     queryParams.push(`year=${encodeURIComponent(filter)}`);
   }
   if (typeof filter === 'string' && filter.includes('day')) {
     queryParams.push(`day=${encodeURIComponent(true)}`);
   }
   if (typeof filter === 'string' && filter.includes('week')) {
     queryParams.push(`week=${encodeURIComponent(true)}`);
   }
   if (typeof filter === 'string' && filter.includes('month')) {
     queryParams.push(`month=${encodeURIComponent(true)}`);
   }
   if (typeof filter === 'string' && !Number.isNaN(Date.parse(filter))) {
     queryParams.push(`specific=${encodeURIComponent(filter.toString())}`);
   }

   // Join the query parameters with "&"
   const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

   // Make the API call with the constructed query string
   return api.get(`/schools/overview/${queryString}`, (res) => res);
 };

 fetchUsers(
   page: number,
   pageSize: number,
   searchString: string,
   schoolId: string,
 ): Promise<any> {
   return api.get(
     // eslint-disable-next-line max-len
     `/schools/${parseInt(schoolId, 10)}/members?type=${Role.ACCOUNTANT}&${searchString.length > 0 ? `search=${searchString}&` : ''}page=${page + 1}&limit=${pageSize}`,
     (res) => res,
   );
 }

 changeStatus(payload: IStatus, schoolId: string): Promise<any> {
   // eslint-disable-next-line max-len
   return api.put(`/schools/${parseInt(schoolId, 10)}/members/${payload?.user_id}/${payload?.action}`,
     {},
     (res) => toast.success(res.message));
 }

 changeFeeStatus(payload: Record<string, any>, schoolId: string): Promise<any> {
   return api.put(`/schools/${parseInt(schoolId, 10)}/fees/${payload.fee_id}/${payload.action}`,
     {},
     (res) => toast.success(res.message));
 }

 updateInfo(payload: Record<string, any>, schoolId: string): Promise<any> {
   return api.put(
     `/schools/${parseInt(schoolId, 10)}/members/${payload.user_id}`,
     payload.body,
     (res) => toast.success(res.message),
   );
 }

 updateService(payload: Record<string, any>, schoolId: string): Promise<any> {
   return api.put(`/schools/${parseInt(schoolId, 10)}/fees/${payload.id}`,
     payload.body,
     (res) => toast.success(res.message));
 }
}

export default School;
