import React from 'react';
import { BiMenu } from 'react-icons/bi';
import { Dropdown } from '..';
import { Role } from '../../helpers';
import { subMenuProfile as adminSubMenu } from '../../pages/Admin/local-helpers/SubMenuProfile';
import { subMenuProfile as schoolSubMenu } from '../../pages/School/local-helpers/SubMenuProfile';
import { subMenuProfile as accSubMenu } from '../../pages/Accountant/SubMenuProfile';

const Header: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  return (
    <header
      className="z-20 py-4 bg-white
        border-b border-gray-light"
    >
      <div
        className="container flex items-center
          justify-end h-full px-6 mx-auto"
      >
        {/* <!-- Mobile hamburger --> */}
        <button
          type="button"
          className="p-1 -ml-1 mr-5 rounded-md md:hidden
              focus:outline-none focus:shadow-outline-green"
          aria-label="hamburger menu"
        >
          <BiMenu className="w-6 h-6" />
        </button>
        <ul className="flex items-center flex-shrink-0 space-x-6">
          {currentUser?.role === Role.ADMIN && (
            <Dropdown menu={adminSubMenu} currentUser={currentUser}>
              <span className="text-xs uppercase font-semibold">
                Administrator
              </span>
            </Dropdown>
          )}
          {currentUser?.role === Role.SCHOOL_ADMIN && (
            <Dropdown menu={schoolSubMenu} currentUser={currentUser}>
              <div
                className="object-cover w-8 h-8 rounded-full
                  border-2 border-white bg-yellow text-yellow-light shadow
                  flex flex-col items-center justify-center font-bold"
              >
                {currentUser?.schools[0]?.name?.split('')[0]}
              </div>
            </Dropdown>
          )}
          {currentUser?.role === Role.ACCOUNTANT && (
          <Dropdown menu={accSubMenu} currentUser={currentUser}>
            <div
              className="object-cover w-8 h-8 rounded-full
                  border-2 border-white bg-yellow text-yellow-light shadow
            flex flex-col items-center justify-center font-bold"
            >
              {currentUser?.schools[0]?.name?.split('')[0]}
            </div>
          </Dropdown>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;
