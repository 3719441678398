/* eslint-disable func-names */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  Layout,
  Content,
  Header,
  InputText,
  FormLayout,
  Button,
  Select,
} from '../../components';
import { menu } from './local-helpers/MenuItems';
import {
  regPhone,
} from '../../helpers';
import School, { ICreateAccount } from './local-helpers/requests';
import { ISelectedValue } from '../../components/Select';

const CreateAccount: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const API = new School();

  const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  React.useEffect(() => () => toast.dismiss(), []);

  const {
    mutate, isLoading,
  } = useMutation((payload: ICreateAccount) => API.createAccountantAcc(payload));

  const formik = useFormik({
    initialValues: {
      scha_first_name: '',
      scha_last_name: '',
      scha_email: '',
      scha_username: '',
      scha_phone: '',
      scha_gender: '',
    },
    validationSchema: Yup.object({
      scha_first_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required('First name is required.'),
      scha_last_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required('Last name is required.'),
      scha_email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
      scha_gender: Yup.string().required('Gender is required.'),
      scha_phone: Yup.string()
        .matches(regPhone, 'Valid format 2507 followed by 8 digits..')
        .min(12, 'Valid format 2507 followed by 8 digits.')
        .max(12, 'Valid format 2507 followed by 8 digits.')
        .required('Phone number is required.'),
      scha_username: Yup.string()
        .min(3, 'Must at least be 3 characters.')
        .required('Username is required.'),
    }),
    onSubmit: (values) => {
      mutate({
        first_name: values.scha_first_name,
        last_name: values.scha_last_name,
        email: values.scha_email,
        username: values.scha_username,
        gender: values.scha_gender,
        phone_number: values.scha_phone,
        role: 'accountant',
        school_id: currentUser?.schools[0].id,
      });
    },
  });
  return (
    <Layout menuItems={menu}>
      <Header />
      <Content title="Create Account">
        <form onSubmit={formik.handleSubmit}>
          <FormLayout
            title="Account information"
            description="Brief information about
          the account's user"
          >
            <div className="grid grid-cols-2 gap-5">
              <InputText
                id="scha_first_name"
                type="text"
                placeholder="John"
                value={formik.values.scha_first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="first name"
                errorMsg={
                  formik.touched.scha_first_name
                  && formik.errors.scha_first_name
                    ? formik.errors.scha_first_name
                    : ''
                }
              />
              <InputText
                id="scha_last_name"
                type="text"
                placeholder="Doe"
                value={formik.values.scha_last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="last name"
                className="ml-4"
                errorMsg={
                  formik.touched.scha_last_name && formik.errors.scha_last_name
                    ? formik.errors.scha_last_name
                    : ''
                }
              />
              <InputText
                id="scha_email"
                type="email"
                placeholder="john.doe@email.com"
                value={formik.values.scha_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="email address"
                errorMsg={
                  formik.touched.scha_email && formik.errors.scha_email
                    ? formik.errors.scha_email
                    : ''
                }
              />
              <InputText
                id="scha_username"
                type="text"
                placeholder="jod123"
                value={formik.values.scha_username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="username"
                className="ml-4"
                errorMsg={
                  formik.touched.scha_username && formik.errors.scha_username
                    ? formik.errors.scha_username
                    : ''
                }
              />
              <Select
                id="scha_gender"
                placeholder="Select gender..."
                label="gender"
                onChange={(option: ISelectedValue) => {
                  formik.setFieldValue('scha_gender', option.value);
                }}
                onBlur={formik.handleBlur}
                options={genders}
                errorMsg={
                  formik.touched.scha_gender && formik.errors.scha_gender
                    ? formik.errors.scha_gender
                    : ''
                }
              />
              <InputText
                id="scha_phone"
                type="tel"
                placeholder="2507********"
                value={formik.values.scha_phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="phone number"
                className="ml-4"
                errorMsg={
                  formik.touched.scha_phone && formik.errors.scha_phone
                    ? formik.errors.scha_phone
                    : ''
                }
              />
            </div>

            <div className="flex justify-end mt-6">
              <Button
                type="reset"
                variant="secondary"
                onClick={() => formik.resetForm()}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" className="ml-4" loading={isLoading}>
                Create
              </Button>
            </div>
          </FormLayout>
        </form>
      </Content>
    </Layout>
  );
};

export default CreateAccount;
