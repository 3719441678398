import React from 'react';
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  RouteComponentProps,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { ROUTE } from './routes';

interface Props {
  component: React.ElementType;
  roles: string[];
  path?: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<Props> = ({
  component: Component,
  roles,
  exact = false,
  ...rest
}: Props) => {
  const history = useHistory();
  const currentUser = JSON.parse(
    localStorage.getItem('currentUser') || '{}',
  );

  return (
    <Route
      {...rest}
      exact={exact}
      render={(props: RouteComponentProps<any>): any => {
        if (isEmpty(currentUser)) {
          // not logged in so redirect to login page with the return url
          return (
            <Switch>
              <Redirect
                to={{
                  pathname: ROUTE.HOME,
                  state: {
                    from: props.location,
                  },
                }}
              />
            </Switch>
          );
        }
        // check if route is restricted by role
        // if ((roles && roles.indexOf(currentUser.role) === -1) === false) {

        if (!roles?.includes(currentUser?.role)) {
          // role not authorised so redirect to home page
          return history.go(-1);
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
