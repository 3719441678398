import React from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  Tabs, Layout, Content, Header,
} from '../../../components';
import { menu } from '../local-helpers/MenuItems';
import { menu as accMenu } from '../../Accountant/MenuItems';
import { Role } from '../../../helpers';
import UploadStudents from './UploadStudents';
import Students from './Students';
import CampAndDept from './CampAndDept';
import School from '../local-helpers/requests';

const StudentManagement: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const schoolName = currentUser?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');
  const API = new School();
  const size = 30;
  useQuery('campuses', () => API.fetchCampuses(currentUser.schools[0].id));
  useQuery('departments', () => API.fetchDepartments(size, currentUser.schools[0].id));

  React.useEffect(() => () => toast.dismiss(), []);

  return (
    <Layout menuItems={
      currentUser?.role === Role.SCHOOL_ADMIN
        ? menu
        : currentUser?.role === Role.ACCOUNTANT
          ? accMenu
          : []
}
    >
      <Header />
      <Content title={isGasabo ? 'Sellers Management' : 'Students Management'}>
        <Tabs>
          {isGasabo ? 'List of Sellers' : 'List of Students'}
          <span><Students /></span>
          {currentUser?.role === Role.SCHOOL_ADMIN && (isGasabo ? 'Upload Sellers' : 'Upload Students')}
          {currentUser?.role === Role.SCHOOL_ADMIN && <span><UploadStudents /></span>}
          {isGasabo ? 'Zones' : 'Campuses & Departments '}
          <span><CampAndDept /></span>
        </Tabs>
      </Content>
    </Layout>
  );
};

export default StudentManagement;
