import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { menu } from './local-helpers/MenuItems';
import { menu as accMenu } from '../Accountant/MenuItems';
import { Role } from '../../helpers';
import CustomContext from '../../contexts/CustomContext';
import School from './local-helpers/requests';
import { tableReducer } from '../../components/Table/TableReducer';
import {
  Layout,
  Content,
  Header,
  Table,
  Button,
  Select,
  Badge,
} from '../../components';
import { calculateDateRange } from '../../helpers/functions-and-objects';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const yearOptions = [
  { label: '2024', value: '2024' },
  { label: '2023', value: '2023' },
  { label: '2022', value: '2022' },
  { label: '2021', value: '2021' },
  // Add more years as needed
];

const monthOptions = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const statusOptions = [
  { label: 'Paid', value: 'paid' },
  { label: 'Not Paid', value: 'not_paid' },
  { label: 'Partially Paid', value: 'partially_paid' },
];

const PaymentsReport: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const schoolId = currentUser?.schools[0]?.id;
  const schoolName = currentUser?.schools?.[0]?.name;
  const isGasabo = schoolName?.includes('Gasabo');

  const API = new School();

  const [selectedYear, setSelectedYear] = React.useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = React.useState<number | null>(null);
  const [zone, setZone] = useState(0);
  const [status, setStatus] = useState<string | null>(null);
  const [searchString, setSearchString] = React.useState('');

  const { startStr, endStr } = selectedYear && selectedMonth
    ? calculateDateRange(selectedYear, selectedMonth)
    : { startStr: '', endStr: '' };

  React.useEffect(() => () => toast.dismiss(), []);

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const { data: departmentsData, isSuccess: isDepartmentsSuccess } = useQuery(
    'departments',
    () => API.fetchDepartments(100, schoolId),
  );

  const zoneOptions = React.useMemo(() => {
    if (isDepartmentsSuccess && departmentsData) {
      return departmentsData.data.map(
        (department: { id: string; name: string }) => ({
          label: department.name,
          value: department.id,
        }),
      );
    }
    return [];
  }, [departmentsData, isDepartmentsSuccess]);

  const columns = React.useMemo(
    () => [
      { Header: 'Transaction ID', accessor: 'transaction_id' },
      { Header: 'Names', accessor: 'names' },
      { Header: 'Phone number', accessor: 'phone_number' },
      { Header: 'Zone', accessor: 'department_name' },
      { Header: 'Month', accessor: 'month' },
      { Header: 'Amount to be paid', accessor: 'amount_to_be_paid' },
      { Header: 'Amount paid', accessor: 'amount_paid' },
      { Header: 'Remaining', accessor: 'remaining' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const amountToBePaid = parseInt(original.amount_to_be_paid, 10) || 0;
          const amountPaid = parseInt(original.amount_paid, 10) || 0;
          const remaining = amountToBePaid - amountPaid;

          let statusText = 'not paid';
          let badgeColor = 'red'; // Default color

          if (remaining === 0) {
            statusText = 'paid';
            badgeColor = 'green';
          } else if (remaining > 0 && remaining < amountToBePaid) {
            statusText = 'partially paid';
            badgeColor = 'yellow';
          } else if (remaining < 0) {
            statusText = 'overpaid';
            badgeColor = 'blue';
          }

          return (
            <Badge variant={badgeColor}>
              {statusText}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const { data, isLoading, isSuccess } = useQuery(
    [
      'transactions',
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      startStr,
      endStr,
      zone,
    ],
    () => API.fetchTransactions(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
      startStr,
      endStr,
      currentUser.schools[0].id,
      zone,
    ),
    {
      enabled: !!selectedYear && !!selectedMonth, // Only fetch if both year and month are selected
    },
  );

  const transactionsData = React.useMemo(() => {
    if (!isSuccess || !data) return [];

    return data.data.map((transaction: any) => {
      const amountToBePaid = parseInt(transaction.sage_id, 10);
      const amountPaid = parseInt(transaction.amount, 10);
      const remaining = amountToBePaid - amountPaid;
      const paymentStatus = remaining === 0
        ? 'paid'
        : remaining > 0 && remaining < amountToBePaid
          ? 'partially paid'
          : remaining < 0
            ? 'overpaid'
            : 'not paid';

      return {
        transaction_id: transaction.external_transaction_id,
        names: transaction.payer_names,
        phone_number: transaction.user?.phone_number || 'N/A',
        department_name: transaction.department?.name || 'N/A',
        month: transaction.month,
        amount_to_be_paid: amountToBePaid.toLocaleString(),
        amount_paid: amountPaid.toLocaleString(),
        remaining: remaining.toLocaleString(),
        status: paymentStatus,
        meta: data.meta,
      };
    });
  }, [data, isSuccess]);

  const convertToCSV = (dataa: any[]): any => {
    if (data.length === 0) return '';

    const headers = `${Object.keys(dataa[0]).join(',')}\n`;
    const rows = dataa.map((row) => Object.values(row)
      .map((value) => `"${value}"`)
      .join(',')).join('\n');

    return headers + rows;
  };

  const downloadCSV = (csvData: string, filename: string): any => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadReport = (): any => {
    if (!transactionsData || transactionsData.length === 0) {
      toast.error('No data available to download');
      return;
    }

    const csvData = convertToCSV(transactionsData);
    if (csvData) {
      downloadCSV(csvData, `payments_report_${selectedYear}_${selectedMonth}.csv`);
    } else {
      toast.error('Failed to generate CSV');
    }
  };

  return (
    <Layout
      menuItems={
        currentUser?.role === Role.SCHOOL_ADMIN
          ? menu
          : currentUser?.role === Role.ACCOUNTANT
            ? accMenu
            : []
      }
    >
      <Header />
      <Content title="Payments Report">
        <CustomContext.Provider value={stateProvider}>
          <div className="w-full flex justify-between align-bottom ">
            <div className="w-3/4 flex gap-2">
              <Select
                id="year-select"
                options={yearOptions}
                placeholder="Select Year"
                label="Year"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedYear(Number(option.value))}
                // onBlur={() => { }}
                errorMsg=""
              />

              <Select
                id="month-select"
                options={monthOptions}
                placeholder="Select Month"
                label="Month"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setSelectedMonth(Number(option.value))}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={!selectedYear}
              />

              <Select
                id="zone-select"
                options={zoneOptions}
                placeholder="Select Zone"
                label="Zone"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setZone(Number(option.value))}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={!selectedMonth}
              />

              {/* <Select
                id="status-select"
                options={statusOptions}
                placeholder="Select Status"
                label="Status"
                onChange={(option: {
                  value: React.SetStateAction<string | null>;
                }) => setStatus(option.value)}
                // onBlur={() => { }}
                errorMsg=""
                isDisabled={!selectedMonth}
              /> */}
            </div>
            <div className="w-1/4 justify-end">
              <Button type="button" onClick={handleDownloadReport}>
                Download Report
              </Button>
            </div>
          </div>
          {selectedYear && selectedMonth ? (
            <Table
              columns={columns}
              data={transactionsData}
              meta={transactionsData.meta}
              loading={isLoading}
              countPage={
                isSuccess
                  ? Math.ceil(state.totalCount / state.queryPageSize)
                  : undefined
              }
              searchLabel="search payments"
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          ) : (
            <div className=" text-lg mt-6 text-gray-600">
              Use filters above to view the reports table...
            </div>
          )}
        </CustomContext.Provider>
      </Content>
    </Layout>
  );
};

export default PaymentsReport;
