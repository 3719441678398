import React from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  InputText,
  FormLayout,
  Button,
  Select,
  Modal,
} from '../../../../components';
import School, { ICreateDept } from '../../local-helpers/requests';
import { ISelectedValue } from '../../../../components/Select';

interface ISelect {
  value: string | number;
  label: string | number;
}

type Props = {
  isCreateDept: boolean,
  onClose: () => void,
  campuses: ISelect[],
  isGasabo : boolean
}

const CreateDepartment: React.FC<Props> = ({
  isCreateDept, onClose, campuses, isGasabo,
}: Props) => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const API = new School();
  const {
    mutate, isLoading,
  } = useMutation((payload: ICreateDept) => API.createDept(payload, currentUser.schools[0].id));

  React.useEffect(() => () => toast.dismiss(), []);

  const dFormik = useFormik({
    initialValues: { scha_dept_name: '', scha_dept_campus: 0 },
    validationSchema: Yup.object({
      scha_dept_name: Yup.string()
        .min(3, 'Must be at least 3 characters.')
        .required(isGasabo ? 'Zone name is required' : 'Department name is required'),
      scha_dept_campus: Yup.number().required(isGasabo ? 'Location is required.' : 'Campus is required.'),
    }),
    onSubmit: (values) => {
      mutate({ name: values.scha_dept_name, campus_id: values.scha_dept_campus });
    },
  });

  return (
    <form onSubmit={dFormik.submitForm}>
      <Modal
        isOpen={isCreateDept}
        title={isGasabo ? 'Create zone' : 'Create department'}
        onClose={onClose}
        footerContent={(
          <div className="flex justify-end">
            <Button
              type="submit"
              variant="primary"
              className="ml-4"
              loading={isLoading}
            >
              {isGasabo ? 'Create zone' : 'Create department'}
            </Button>
          </div>
        )}
      >
        <FormLayout title={isGasabo ? 'Zone' : 'Department'} description={isGasabo ? 'Select a location and then type a zone you want to create.' : 'Select a campus and then type a zone you want to create.'}>
          <Select
            id="scha_dept_campus"
            label="campus"
            placeholder={isGasabo ? 'Select location' : 'Select campus...'}
            className="text-black"
            options={campuses}
            onChange={(option: ISelectedValue) => {
              dFormik.setFieldValue('scha_dept_campus', option.value);
            }}
            onBlur={dFormik.handleBlur}
            errorMsg={
              dFormik.touched.scha_dept_campus
                && dFormik.errors.scha_dept_campus
                ? dFormik.errors.scha_dept_campus
                : ''
            }
          />
          <InputText
            id="scha_dept_name"
            label="Name"
            type="text"
            placeholder={isGasabo ? 'Zone name' : 'Department name'}
            value={dFormik.values.scha_dept_name}
            onChange={dFormik.handleChange}
            onBlur={dFormik.handleBlur}
            errorMsg={
              dFormik.touched.scha_dept_name && dFormik.errors.scha_dept_name
                ? dFormik.errors.scha_dept_name
                : ''
            }
          />
        </FormLayout>
      </Modal>
    </form>
  );
};

export default CreateDepartment;
