/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  Column,
  useExpanded,
  useFilters,
  FilterProps,
} from 'react-table';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { debounce } from 'lodash';
import TableSkeleton from '../Skeletons/Table';
import { InputText, Button, Empty } from '..';
import { useCustomContext } from '../../contexts/CustomContext';

type Props = {
  columns: Column<Record<string, any>>[] | [];
  data: Record<string, any>[] | [];
  meta?: Record<string, any>;
  searchLabel: string;
  countPage?: number | undefined;
  loading?: boolean;
  renderSubRow?: any;
  onChangeCallback?: (arg: string) => void;
  search?: boolean;
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const DefaultColumnFilter: React.FC<FilterProps<Record<string, any>>> = ({
  column: { filterValue, setFilter },
}) => (
  <input
    value={filterValue || ''}
    onChange={(e) => {
      setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    }}
  />
);

const Table: React.FC<any> = ({
  columns,
  data,
  meta,
  countPage,
  loading,
  searchLabel,
  renderSubRow,
  onChangeCallback,
  search,
}: Props) => {
  const [filter, setFilter] = React.useState('');
  const { state, dispatch } = useCustomContext();

  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    visibleColumns,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: state?.queryPageIndex,
        pageSize: state?.queryPageSize,
      },
      manualPagination: true,
      pageCount: countPage,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  React.useEffect(() => {
    if (dispatch) dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [dispatch, pageIndex]);

  React.useEffect(() => {
    if (dispatch) {
      dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
      gotoPage(0);
    }
  }, [pageSize, gotoPage, dispatch]);

  React.useEffect(() => {
    if (dispatch) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: meta?.total,
      });
    }
  }, [dispatch, meta?.total]);

  const handleSearch = React.useMemo(
    () => debounce(async (searchQuery: string): Promise<void> => {
      if (onChangeCallback !== undefined) await onChangeCallback(searchQuery);
    }, 300),
    [onChangeCallback],
  );

  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const searchQuery = e.target.value;
      setFilter(searchQuery);
      await handleSearch(searchQuery);
    },
    [handleSearch],
  );

  React.useEffect(
    () => () => {
      handleSearch.cancel();
    },
    [handleSearch],
  );

  return (
    <>
      <div className="flex w-full mb-3 justify-between items-center">
        {search && (
          <div className="flex w-5/12 items-center mt-3">
            <InputText
              type="search"
              id="global_search"
              className=" mr-1 w-2/3"
              placeholder="Search Table..."
              label={searchLabel}
              value={filter || ''}
              onChange={handleChange}
              onPaste={handleChange}
              onEnter={handleChange}
            />
          </div>
        )}
      </div>

      <div className="w-full overflow-hidden rounded-lg shadow-md">
        <div className="w-full overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full whitespace-no-wrap text-sm"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="text-xs font-semibold tracking-wide text-left
                 text-gray-dark uppercase border-b border-gray-bg
                  bg-gray-light"
                >
                  {headerGroup.headers.map((column) => (
                    <th className="px-4 py-3">
                      <div className="flex items-center">
                        <div
                          className="mr-2"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                        >
                          {column.render('Header')}
                          <div
                            className={`${
                              (page.length === 0 || loading) && 'hidden'
                            }`}
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <p className=" text-base ml-1 hover:shadow-md">
                                  ⇃
                                </p>
                              ) : (
                                <p className="text-base ml-1 hover:shadow-md">
                                  ↾
                                </p>
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {/* <div>{column.render('Filter')}</div> */}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr
                      {...row.getRowProps()}
                      className="text-black border-gray-bg border-b on hover:bg-yellow-light"
                    >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} className="px-4 py-5">
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                    {row.isExpanded
                      && renderSubRow({ row, rowProps, visibleColumns })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        {loading && <TableSkeleton />}
        {!loading && page.length === 0 && <Empty />}
        {!loading && page.length > 0 && pageOptions.length > 0 && (
          <div
            className="grid px-4 py-3 text-xs
         font-semibold tracking-wide text-gray-dark
         uppercase sm:grid-cols-9 overflow-visible"
          >
            <span className="flex items-center col-span-3">
              Showing
              {' '}
              {pageIndex + 1}
              {' '}
              of
              {' '}
              {pageOptions.length}
            </span>
            <span className="col-span-2" />
            <span className="flex col-span-4 sm:mt-auto sm:justify-end">
              <nav aria-label="Table navigation">
                <ul className="flex items-center">
                  <li>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="small"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      First
                    </Button>
                  </li>
                  <li className="mx-1">
                    <Button
                      type="button"
                      variant="tertiary"
                      size="small"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiChevronLeft />
                    </Button>
                  </li>
                  <li className="mx-1">
                    <Button
                      type="button"
                      variant="tertiary"
                      size="small"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiChevronRight />
                    </Button>
                  </li>
                  <li className="mx-1">
                    <Button
                      type="button"
                      variant="tertiary"
                      size="small"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      Last
                    </Button>
                  </li>
                  <li className="flex items-center mx-1 w-full">
                    <span className="mr-1 w-full">| Go to page: </span>
                    <InputText
                      id="go_to"
                      type="number"
                      min="1"
                      max={pageOptions.length}
                      defaultValue={pageIndex + 1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const aPage = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(aPage);
                      }}
                      className="mb-1"
                    />
                  </li>
                  <li className="w-1/2">
                    <select
                      value={pageSize}
                      className="input-text text-smuppercase font-semibold"
                      onChange={(e) => {
                        const newSize = Number(e.target.value);
                        setPageSize(newSize);
                        gotoPage(0);
                      }}
                    >
                      {[
                        10, 20, 30, 40, 50, 100, 500, 1000, 2500, 5000, 10000,
                      ].map((size) => (
                        <option key={size} value={size}>
                          Show
                          {' '}
                          {size}
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
              </nav>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Table);
