/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { System } from './local-helpers/requests';
import { menu } from './local-helpers/MenuItems';
import {
  Layout,
  Header,
  Content,
  Table,
  Badge,
} from '../../components';
import CustomContext from '../../contexts/CustomContext';
import { tableReducer } from '../../components/Table/TableReducer';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const system = new System();

const Universities: React.FC = () => {
  const [searchString, setSearchString] = React.useState('');

  React.useEffect(() => () => toast.dismiss(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Abbreviation',
        accessor: 'abbreviation',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Website',
        accessor: 'website',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          Moment(original?.created_at)
            .format('DD/MM/YYYY, h:mm:ss a')),
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          Moment(original?.updated_at)
            .format('DD/MM/YYYY, h:mm:ss a')),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          let variant: 'yellow' | 'red' | 'green' = 'yellow';
          switch (original?.status) {
            case 'active':
              variant = 'green';
              break;
            case 'inactive':
              variant = 'red';
              break;
            default:
              return '';
          }

          return (
            <>
              <Badge
                variant={variant}
                onClick={() => {
                  // setChangeStatus(true);
                  // setAcc({
                  //   acc_number: original?.bank_account_number,
                  //   bank: original?.type,
                  //   status: original?.status,
                  //   acc_id: original?.id,
                  //   school_id: original?.school_id,
                  // });
                }}
              >
                {original?.status}
              </Badge>
            </>
          );
        },
      },
    ],
    [],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const { isLoading, data, isSuccess } = useQuery(
    ['schools', state.queryPageIndex, state.queryPageSize, searchString],
    () => system.getUniversities(state.queryPageIndex, state.queryPageSize, searchString),
  );

  return (
    <>
      <Layout menuItems={menu}>
        <Header />
        <Content title="Universities">
          <CustomContext.Provider value={stateProvider}>
            <Table
              searchLabel="search universities"
              data={isSuccess ? data?.data : []}
              columns={columns}
              meta={isSuccess ? data?.meta : []}
              countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
              loading={isLoading}
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export { Universities };
