import React from 'react';
import { useQuery, useMutation } from 'react-query';
import Moment from 'moment';
import Tooltip from 'react-tooltip';
import { FaUserEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  Layout, Content, Header, Table, Button,
} from '../../components';
import { tableReducer } from '../../components/Table/TableReducer';
import { menu } from './local-helpers/MenuItems';
import School, { IStatus } from './local-helpers/requests';
import CustomContext from '../../contexts/CustomContext';
import UpdateUser from './UpdateUser';

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const Users: React.FC = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  const API = React.useMemo(() => new School(), []);
  const [searchString, setSearchString] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<Record<string, any>>({});

  React.useEffect(() => () => toast.dismiss(), []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'UserId',
      },
      {
        Header: 'First Name',
        accessor: (row: Record<string, any>) => [row?.user?.first_name],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.user?.first_name,
      },
      {
        Header: 'Last Name',
        accessor: (row: Record<string, any>) => [row?.user?.last_name],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.user?.last_name,
      },
      {
        Header: 'Email',
        accessor: (row: Record<string, any>) => [row?.user?.email],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => original?.user?.email,
      },
      {
        Header: 'Phone number',
        accessor: (row: Record<string, any>) => [row?.user?.phone_number],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => `+${original?.user?.phone_number}`,
      },
      {
        Header: 'Created at',
        accessor: (row: Record<string, any>) => [row?.created_at],
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.created_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'created at',
      },
      {
        Header: 'Updated at',
        accessor: (row: Record<string, any>) => [row?.updated_at],
        Cell: ({
          row: { original },
        }: Record<string, any>): React.ReactNode => Moment(original?.updated_at)
          .format('DD/MM/YYYY, h:mm:ss a')
          || 'updated at',
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        Cell: ({
          row:
          { original },
        }: Record<string, any>): React.ReactNode => (
          <Button
            type="button"
            variant="tertiary"
            size="small"
            onClick={() => { setIsEdit(true); setUserInfo(original); }}
          >
            <FaUserEdit size={18} />
          </Button>
        )
        ,
      },
      {
        Header: 'Status',
        accessor: (row: any) => [row?.status],
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          const {
            mutate,
            isLoading,
          } = useMutation((payload: IStatus) => API.changeStatus(
            payload,
            currentUser.schools[0].id,
          ));
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  mutate({
                    action:
                      original?.status === 'active' ? 'deactivate' : 'activate',
                    user_id: original?.user_id,
                  });
                }}
              >
                <button
                  type="submit"
                  data-tip
                  data-for="status-change"
                  className={`text-xs px-2 font-medium
              ${
                original?.status === 'active'
                  ? 'text-green-alertText bg-green-alertText'
                  : 'text-red-alertText bg-red-alertText'
              }
                bg-opacity-10 rounded py-0.5 mr-1`}
                >
                  {isLoading ? 'changing...' : original?.status}
                </button>
              </form>

              <Tooltip id="status-change" type="light">
                {' '}
                Click to change status.
              </Tooltip>
            </>
          );
        },
      },
    ],
    [API, currentUser.schools],
  );

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const {
    data,
    isSuccess,
    isLoading,
  } = useQuery(['accountants',
    state.queryPageIndex,
    state.queryPageSize,
    searchString],
  () => API.fetchUsers(state.queryPageIndex,
    state.queryPageSize,
    searchString,
    currentUser.schools[0].id));

  return (
    <>
      <UpdateUser
        isUpdateUser={isEdit}
        onClose={() => { setIsEdit(false); setUserInfo({}); }}
        info={userInfo}
      />
      <Layout menuItems={menu}>
        <Header />
        <Content title="Users">
          <CustomContext.Provider value={stateProvider}>
            <Table
              searchLabel="search users"
              data={isSuccess ? data?.data : []}
              columns={columns}
              meta={isSuccess ? data?.meta : []}
              countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
              loading={isLoading}
              onChangeCallback={(e: any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export default Users;
