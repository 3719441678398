import React from 'react';
import { toast } from 'react-toastify';
import {
  Layout,
  Content,
  Header,
  Tabs,
} from '../../../components';
import { menu } from '../local-helpers/MenuItems';
import PaymentAccount from './PaymentAccount';
import CreateService from './CreateService';

const Settings: React.FC = () => {
  React.useEffect(() => () => toast.dismiss(), []);

  return (
    <Layout menuItems={menu}>
      <Header />
      <Content title="Settings">
        <Tabs>
          Services
          <CreateService />
          Payment
          <PaymentAccount />
        </Tabs>
      </Content>
    </Layout>
  );
};

export default Settings;
