/* eslint-disable camelcase */
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Moment from 'moment';
import { useFormik } from 'formik';
import Tooltip from 'react-tooltip';
import { System } from './local-helpers/requests';
import { menu } from './local-helpers/MenuItems';
import { ISelectedValue } from '../../components/Select';
import {
  Layout,
  Header,
  Content,
  Table,
  Badge,
  Modal,
  Select,
  Button,
} from '../../components';
import CustomContext from '../../contexts/CustomContext';
import { tableReducer } from '../../components/Table/TableReducer';

interface IAcc {
  acc_number: string;
  bank: string;
  status: string;
  acc_id: string;
  school_id: '';
}

interface IPayload {
  id: string;
  action: 'approve' | 'reject' | string;
  school: string
}

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: 0,
};

const PaymentAccounts: React.FC = () => {
  const request = new System();
  const [changeStatus, setChangeStatus] = React.useState(false);
  const [acc, setAcc] = React.useState<IAcc>({
    acc_number: '',
    bank: '',
    status: '',
    acc_id: '',
    school_id: '',
  });
  const options = [
    { label: 'Approve', value: 'approve' },
    { label: 'Reject', value: 'reject' },
  ];
  const [searchString, setSearchString] = React.useState('');

  const statusChange = useMutation((payload: IPayload) => request.changePayStatus(
    payload.id,
    payload.action,
    payload.school,
  ));

  const system = new System();

  React.useEffect(() => () => toast.dismiss(), []);

  const formik = useFormik({
    initialValues: {
      sysa_change_pay_status: '',
    },
    validationSchema: Yup.object({
      sysa_change_pay_status: Yup.mixed()
        .oneOf(['approve', 'reject'])
        .required('Action is required.'),
    }),
    onSubmit: (values) => {
      statusChange.mutate({
        id: acc.acc_id,
        action: values.sysa_change_pay_status,
        school: acc.school_id,
      });
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'Account name',
        accessor: 'name',
      },
      {
        Header: 'Bank',
        accessor: 'type',
      },
      {
        Header: 'Account number',
        accessor: 'bank_account_number',
      },
      {
        Header: 'School',
        accessor: (row: Record<string, any>) => [row?.school?.name],
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          Moment(original?.created_at)
            .format('DD/MM/YYYY, h:mm:ss a')),
      },
      {
        Header: 'Updated At',
        accessor: 'updated_at',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => (
          Moment(original?.updated_at)
            .format('DD/MM/YYYY, h:mm:ss a')),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }: Record<string, any>): React.ReactNode => {
          let variant: 'yellow' | 'red' | 'green' = 'yellow';

          switch (original?.status) {
            case 'pending':
              variant = 'yellow';
              break;
            case 'rejected':
              variant = 'red';
              break;
            case 'approved':
              variant = 'green';
              break;
            default:
              return '';
          }

          return (
            <>
              <span
                data-tip
                data-for={original?.status === 'pending' && 'acc-change'}
              >
                <Badge
                  variant={variant}
                  onClick={original?.status === 'pending'
                    ? () => {
                      setChangeStatus(true);
                      setAcc({
                        acc_number: original?.bank_account_number,
                        bank: original?.type,
                        status: original?.status,
                        acc_id: original?.id,
                        school_id: original?.school_id,
                      });
                    } : () => null}
                >
                  {original?.status}
                  {' '}
                  {original?.approvals_count === 1 && '(1 approval remaining)'}
                </Badge>
              </span>

              <Tooltip id="acc-change" type="light">
                Click to change status
              </Tooltip>
            </>
          );
        },
      },
    ],
    [],
  );

  const [state, dispatch] = React.useReducer(tableReducer, initialState);

  const stateProvider = {
    state,
    dispatch,
  };

  const handleSearch = (e: string): void => {
    setSearchString(e);
  };

  const { isLoading, data, isSuccess } = useQuery(
    ['payment accounts',
      state.queryPageIndex,
      state.queryPageSize,
      searchString],
    () => system.fetchPaymentAccounts(
      state.queryPageIndex,
      state.queryPageSize,
      searchString,
    ),
  );

  return (
    <>
      <Modal
        title="Change account status"
        onClose={() => setChangeStatus(false)}
        isOpen={changeStatus}
        footerContent={(
          <Button
            type="button"
            loading={statusChange.isLoading}
            onClick={() => formik.handleSubmit()}
          >
            Change status
          </Button>
)}
      >
        <div className="grid grid-cols-2 gap-5">
          <div className="mr-2 text-gray-dark flex flex-col mt-2">
            <span className="mb-1 text-sm">Account number</span>
            <span className="text-black">
              {acc.acc_number}
              /
              {acc.bank}
            </span>
            <span className="mt-6 text-sm flex italic">
              <HiOutlineLightBulb className="mr-1" size={24} />
              {' '}
              Tip: For an account to be approved it requires two aprrovals from system admins.
            </span>
          </div>
          <Select
            id="sysa_change_pay_status"
            options={options}
            placeholder={acc.status}
            label="select action"
            onChange={(option: ISelectedValue) => {
              formik.setFieldValue('sysa_change_pay_status', option.value);
            }}
            onBlur={formik.handleBlur}
            errorMsg={
              formik.touched.sysa_change_pay_status
              && formik.errors.sysa_change_pay_status
                ? formik.errors.sysa_change_pay_status
                : ''
            }
          />
        </div>
      </Modal>
      <Layout menuItems={menu}>
        <Header />
        <Content title="Payment accounts">
          <CustomContext.Provider value={stateProvider}>
            <Table
              searchLabel="search accounts"
              data={isSuccess ? data?.data : []}
              columns={columns}
              meta={isSuccess ? data?.meta : []}
              countPage={
              isSuccess
                ? Math.ceil(state.totalCount / state.queryPageSize)
                : undefined
            }
              loading={isLoading}
              onChangeCallback={(e : any) => handleSearch(e)}
              search
            />
          </CustomContext.Provider>
        </Content>
      </Layout>
    </>
  );
};

export { PaymentAccounts };
