import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import {
  InputText,
  FormLayout,
  Button,
  Select,
} from '../../../components';
import {
  Role, regPhone,
} from '../../../helpers';
import { ISelectedValue } from '../../../components/Select';
import { System, ICreateUser } from '../local-helpers/requests';

const system = new System();

const SchoolAdmin: React.FC = () => {
  const genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ];

  let schools: ISelectedValue[] = [];

  const roles = [
    { value: Role.SCHOOL_ADMIN, label: 'School Admin' },
    { value: Role.ACCOUNTANT, label: 'Accountant' },
  ];

  const sch = useQuery(
    'schools',
    () => system.getUniversities(),
  );

  if (sch.isSuccess) {
    const schoools: Array<Record<string, any>> = sch?.data?.data;
    schoools?.map((school) => {
      schools = [...schools, { label: school.name, value: school.id }];
      return schools;
    });
  }

  const {
    mutate, isLoading,
  } = useMutation((payload: ICreateUser) => system.createUser(payload));

  const formik = useFormik({
    initialValues: {
      sa_first_name: '',
      sa_last_name: '',
      sa_email: '',
      sa_username: '',
      sa_phone: '',
      sa_gender: '',
      sa_acc_type: '',
      sa_school: '',
    },
    validationSchema: Yup.object({
      sa_first_name: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('First name is required.'),
      sa_last_name: Yup.string()
        .min(3, 'Must be at least 3 characters')
        .required('Last name is required.'),
      sa_email: Yup.string()
        .email('Invalid email address.')
        .required('Email is required.'),
      sa_gender: Yup.string().required('Gender is required.'),
      sa_phone: Yup.string()
        .matches(regPhone, 'Valid format 2507 followed by 8 digits.')
        .min(12, 'Valid format 2507 followed by 8 digits.')
        .max(12, 'Valid format 2507 followed by 8 digits.')
        .required('Phone number is required.'),
      sa_username: Yup.string()
        .min(3, 'Must at least be 3 characters.')
        .required('Username is required.'),
      sa_acc_type: Yup.string().required('Role is required.'),
      sa_school: Yup.string().required('School is required.'),
    }),
    onSubmit: (values, { resetForm }) => {
      mutate({
        first_name: values.sa_first_name,
        last_name: values.sa_last_name,
        email: values.sa_email,
        username: values.sa_username,
        gender: values.sa_gender,
        phone_number: values.sa_phone,
        role: values.sa_acc_type,
        school_id: values.sa_school,
      });
      resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout
        title="User Information"
        description="Brief information about
            the user of the application."
      >
        <div className="grid grid-cols-2 gap-5">
          <InputText
            id="sa_first_name"
            type="text"
            placeholder="John"
            value={formik.values.sa_first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="first name"
            errorMsg={
              formik.touched.sa_first_name && formik.errors.sa_first_name
                ? formik.errors.sa_first_name
                : ''
            }
          />
          <InputText
            id="sa_last_name"
            type="text"
            placeholder="Doe"
            value={formik.values.sa_last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="last name"
            className="ml-4"
            errorMsg={
              formik.touched.sa_last_name && formik.errors.sa_last_name
                ? formik.errors.sa_last_name
                : ''
            }
          />
          <InputText
            id="sa_email"
            type="email"
            placeholder="john.doe@email.com"
            value={formik.values.sa_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="email address"
            errorMsg={
              formik.touched.sa_email && formik.errors.sa_email
                ? formik.errors.sa_email
                : ''
            }
          />
          <InputText
            id="sa_username"
            type="text"
            placeholder="jod123"
            value={formik.values.sa_username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="username"
            className="ml-4"
            errorMsg={
              formik.touched.sa_username && formik.errors.sa_username
                ? formik.errors.sa_username
                : ''
            }
          />
          <Select
            id="sa_gender"
            placeholder="Select gender..."
            label="gender"
            onChange={(option: ISelectedValue) => {
              formik.setFieldValue('sa_gender', option.value);
            }}
            onBlur={formik.handleBlur}
            options={genders}
            errorMsg={
              formik.touched.sa_gender && formik.errors.sa_gender
                ? formik.errors.sa_gender
                : ''
            }
          />
          <InputText
            id="sa_phone"
            type="tel"
            placeholder="2507********"
            value={formik.values.sa_phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="phone number"
            className="ml-4"
            errorMsg={
              formik.touched.sa_phone && formik.errors.sa_phone
                ? formik.errors.sa_phone
                : ''
            }
          />
          <Select
            id="sa_acc_type"
            placeholder="Select role..."
            label="user role"
            onChange={(option: ISelectedValue) => {
              formik.setFieldValue('sa_acc_type', option.value);
            }}
            onBlur={formik.handleBlur}
            options={roles}
            errorMsg={
              formik.touched.sa_acc_type && formik.errors.sa_acc_type
                ? formik.errors.sa_acc_type
                : ''
            }
            className="mr-1"
          />
          <Select
            id="sa_school"
            placeholder="Select school..."
            label="assign school"
            onChange={(option: ISelectedValue) => {
              formik.setFieldValue('sa_school', option.value);
            }}
            onBlur={formik.handleBlur}
            options={schools}
            errorMsg={
              formik.touched.sa_school && formik.errors.sa_school
                ? formik.errors.sa_school
                : ''
            }
            className="ml-3"
          />
        </div>
      </FormLayout>
      <div className="flex justify-end mt-6">
        <Button
          type="button"
          variant="secondary"
          onClick={() => formik.resetForm()}
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary" className="ml-4" loading={isLoading}>
          Create User
        </Button>
      </div>
    </form>
  );
};

export { SchoolAdmin };
