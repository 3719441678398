/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */

export enum ROUTE {
  HOME = '/',
  ADMIN = '/admin',
  ADMIN_CREATE_ORG = '/admin/create-organization',
  ADMIN_SETTINGS = '/admin/settings',
  ADMIN_USERS = '/admin/users',
  ADMIN_UNIVS = '/admin/universities',
  ADMIN_PAY_ACCOUNTS = '/admin/payment-accounts',
  ADMIN_TRANSACTIONS= '/admin/transactions',
  SCHOOL_ADMIN = '/school',
  SCHOOL_ADMIN_CREATE_ACC = '/school/create-account',
  SCHOOL_ADMIN_SETTINGS = '/school/settings',
  SCHOOL_ADMIN_STUDENTS = '/school/students',
  SCHOOL_ADMIN_USERS = '/school/users',
  SCHOOL_ADMIN_UPLOAD_STUDENTS = '/school/upload-students',
  SCHOOL_ADMIN_MEMBERS = '/school/members',
  SCHOOL_ADMIN_CAMPUSES = '/school/campuses',
  SCHOOL_ADMIN_DEPARTMENT = '/school/departments',
  SCHOOL_ADMIN_SERVICES = '/school/services',
  SCHOOL_ADMIN_TRANSACTIONS = '/school/transactions',
  SCHOOL_ADMIN_COMMUNICATION = '/school/communications',
  SCHOOL_PAYMENTS_REPORT = '/school/payments-report',

  USERS = '/users',
  USER_PROFILE = '/user-profile/',
  NOT_FOUND = '*',
}
